import { ContainerFluid, Section } from '@/atoms';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { RichtextStoryblok, SectionQuoteStoryblok, ThemeV2Storyblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { BigQuote } from '@/metabloks/BigQuote';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { cls, richTextToString } from '@/utils';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import React from 'react';
import styles from './SectionQuote.module.scss';

interface BlokProps {
	blok: SectionQuoteStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const quote = typeof blok.quote === 'string' ? '' : blok.quote.content.quote;

	return {
		theme: blok.theme?.[0]?.theme ?? 'darkGreen',
		quote,
		linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
		multiColorBgPattern: blok.multiColorBgPattern,
		zone: meta?.zone,
	};
};

interface Props {
	theme: ThemeV2Storyblok['theme'];
	quote: RichtextStoryblok | string;
	linkButton?: LinkButtonProps;
	multiColorBgPattern?: boolean;
	zone?: number;
}
export function SectionQuote({ quote, theme, linkButton, multiColorBgPattern, zone }: Props) {
	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const contentName = `${zone}|SectionQuote`;
	const contentPiece = richTextToString(quote);
	const contentTarget = linkButton ? 'one interaction possible' : 'no interactions possible';
	const addMultiColorBgPattern = multiColorBgPattern && theme === 'white';

	function trackContentInteraction(targetText?: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction(
			'contentInteraction',
			`${slug}|${contentName}`,
			contentPiece,
			contentTarget,
		);
	}

	return (
		<ImpressionTrackingProvider
			value={{
				contentName,
				contentPiece,
				contentTarget,
			}}
		>
			<ContainerFluid
				backgroundPattern={!addMultiColorBgPattern}
				theme={theme}
				className={cls(styles.container, addMultiColorBgPattern && styles.multiColorBgPattern)}
			>
				<Section maxWidth="custom" paddingX={{ base: 'xl', lg: '4xl', xl: '5xl' }} className={styles.section}>
					<BigQuote quote={quote} />
					{linkButton && (
						<div className={styles.buttonContainer}>
							<LinkButton
								size="large"
								{...linkButton}
								onClick={() => {
									trackContentInteraction((linkButton.children as string) ?? '');
								}}
							/>
						</div>
					)}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
}

SectionQuote.blokProps = blokProps;
