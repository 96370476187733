import { BackgroundImage, ContainerFluid, Section } from '@/atoms';
import { HeaderCommon, HeaderCommonProps } from '@/bloks';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { PriceCardGroupV2 } from '@/bloks/PriceCard';
import {
	PriceCardGroupV2Storyblok,
	SectionPriceCardStoryblok,
	ThemeContrastStoryblok,
	ThemeV2Storyblok,
} from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeProvider } from '@/contexts/theme';
import type { ImageAssetProps } from '@/metabloks';
import { getCorrespondingLegacyTheme } from '@/themes';
import styles from './SectionPriceCard.module.scss';

interface BlokProps {
	blok: SectionPriceCardStoryblok;
	meta?: {
		zone?: number;
		containerFoldoutButton?: JSX.Element;
	};
}

const blokProps = ({ blok, meta }: BlokProps): SectionPriceCardProps => {
	const theme = getCorrespondingLegacyTheme(blok?.theme?.[0]?.theme) as ThemeV2Storyblok['theme'];
	return {
		header: blok.header?.[0] && HeaderCommon?.blokProps({ blok: blok.header[0] }),
		backgroundImage: blok.backgroundImage,
		priceCardGroup: blok.priceCardGroup,
		linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
		addContrast: blok.addContrast?.[0]?.theme,
		theme,
		backgroundPattern: blok.backgroundPattern ?? false,
		zone: meta?.zone,
		containerFoldoutButton: meta?.containerFoldoutButton,
	};
};

interface SectionPriceCardProps {
	header: HeaderCommonProps;
	backgroundImage?: ImageAssetProps;
	priceCardGroup: PriceCardGroupV2Storyblok[];
	linkButton?: LinkButtonProps;
	addContrast?: ThemeContrastStoryblok['theme'];
	theme?: ThemeV2Storyblok['theme'];
	backgroundPattern?: boolean;
	zone?: number;
	containerFoldoutButton?: JSX.Element;
}

export const SectionPriceCard: Blok<SectionPriceCardProps, BlokProps> = ({
	priceCardGroup,
	header,
	backgroundImage,
	linkButton,
	addContrast,
	theme,
	backgroundPattern,
	zone,
	containerFoldoutButton,
}) => {
	const hasBackgroundImage = !!backgroundImage?.filename;
	const contentName = `${zone}|SectionPriceCard`;
	const contentPiece = header?.titlePart2 ? `${header?.titlePart1} ${header?.titlePart2}` : header?.titlePart1;

	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece }}>
			<ContainerFluid theme={theme} backgroundPattern={backgroundPattern} contrast={addContrast}>
				{backgroundImage?.filename && (
					<BackgroundImage
						className={styles.backgroundImage}
						zIndex={0}
						asset={backgroundImage}
						objectPosition="center top"
					/>
				)}
				<Section className={styles.section} paddingX={{ base: 'none' }}>
					<div className={styles.header}>
						<HeaderCommon {...header} addContrast={hasBackgroundImage} marginBottom="3xl" />
					</div>

					{priceCardGroup?.map((group) => (
						<PriceCardGroupV2 blok={group} key={group._uid} meta={{ zone: zone }} addContrast={hasBackgroundImage} />
					))}

					{linkButton && (
						<div className={styles.linkButton}>
							<LinkButton {...linkButton} variant="outlined" addContrast={hasBackgroundImage} />
						</div>
					)}

					{containerFoldoutButton && (
						<ThemeProvider contrast={addContrast}>
							<div className={styles.containerFoldoutButton}>{containerFoldoutButton}</div>
						</ThemeProvider>
					)}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionPriceCard.blokProps = blokProps;
