import { ArticlePageStoryblok } from '@/components';
import { ISbStoryData } from '@/types';
import React, { createContext, useContext } from 'react';

/**
 * Provider of related article for content type ArticlePage
 */
export const useRelatedArticles = () => {
	return useContext(Context);
};

interface RelatedArticlesState {
	relatedArticles: {
		byCategory: ISbStoryData<ArticlePageStoryblok>[];
		byLabel: ISbStoryData<ArticlePageStoryblok>[];
	};
}

const Context = createContext<RelatedArticlesState>({
	relatedArticles: { byCategory: [], byLabel: [] },
});

interface Props {
	children: React.ReactNode;
	value: { byCategory: ISbStoryData<ArticlePageStoryblok>[]; byLabel: ISbStoryData<ArticlePageStoryblok>[] };
}
export const RelatedArticlesProviderV2: React.FC<Props> = ({ children, value }) => {
	return <Context.Provider value={{ relatedArticles: value }}>{children}</Context.Provider>;
};
