import { Divider, Heading, TextProps } from '@/atoms';
import { ISbComponentType } from '@/types/storyblok';
import { removeStyleFromRichtext } from '@/utils/removeStyleFromRichtext';
import React, { createElement } from 'react';
import { Document, MARK_LINK, render } from 'storyblok-rich-text-react-renderer';
import { AccordionFaq, AccordionRichtext } from '../Accordion';
import { Blok } from '../Blok';
import { CoverageRateCalculator, EmployeeCalculator, EquityCalculator } from '../Calculator';
import { ProfitMarginCalculator } from '../Calculator/ProfitMarginCalculator';
import { SalaryCalculator } from '../Calculator/SalaryCalculator';
import { VatCalculator } from '../Calculator/VatCalculator';
import { CustomTable, LinkItem, List, TextWrapImage } from '../Other';
import { SectionVideoSmall } from '../Section/SectionVideoSmall';
import { StoryColumns } from '../Story/StoryColumns';
import { StoryTextComponent } from '../Story/StoryTextComponent';
import { ProductUspGroup } from '../Usp';
import { BulletList } from './BulletList';
import { MarkLink } from './MarkLink/MarkLink';
import { Paragraph } from './Paragraph';
import { RichTextLinkButton } from './RichTextLinkButton';
import { RichTextPopUpButton } from './RichTextPopUpButton';
import { TableBlok } from './TableBlok';

interface Meta {
	applyChildTheme?: boolean;
	addContrast?: boolean;
	textSize?: 'default' | 'small' | 'ingress';
	textMarginBottom?: TextProps['marginBottom'];
	exo2?: boolean;
	layout?: 'article';
}

const blokResolverToComponent =
	(Component: React.ComponentType<any>) =>
	/* eslint-disable-next-line react/display-name */
	(blok: ISbComponentType<any>) =>
		createElement(Component, { blok });

const blokResolverToComponentWithBlokProps =
	(Component: Blok<any, any>) =>
	/* eslint-disable-next-line react/display-name */
	(blok: ISbComponentType<any>, meta: Record<string, any>) =>
		createElement(Component, { ...Component.blokProps({ blok, meta }) });

export const renderRichText = (document: Document, meta?: Meta) =>
	render(removeStyleFromRichtext(document), {
		blokResolvers: {
			Table: blokResolverToComponentWithBlokProps(TableBlok),
			LinkButton: blokResolverToComponent(RichTextLinkButton),
			LinkItem: blokResolverToComponentWithBlokProps(LinkItem),
			AccordionFaq: blokResolverToComponent(AccordionFaq),
			List: blokResolverToComponent(List),
			StoryTextComponent: blokResolverToComponent(StoryTextComponent),
			StoryColumns: blokResolverToComponent(StoryColumns),
			CustomTable: blokResolverToComponent(CustomTable),
			TextWrapImage: blokResolverToComponent(TextWrapImage),
			AccordionRichtext: blokResolverToComponentWithBlokProps(AccordionRichtext),
			SectionVideoSmall: blokResolverToComponentWithBlokProps(SectionVideoSmall),
			CoverageRateCalculator: blokResolverToComponent(CoverageRateCalculator),
			EmployeeCalculator: blokResolverToComponent(EmployeeCalculator),
			ProfitMarginCalculator: blokResolverToComponent(ProfitMarginCalculator),
			EquityCalculator: blokResolverToComponent(EquityCalculator),
			SalaryCalculator: blokResolverToComponent(SalaryCalculator),
			VatCalculator: blokResolverToComponent(VatCalculator),
			PopUpButton: blokResolverToComponent(RichTextPopUpButton),
			ProductUspGroup: blokResolverToComponentWithBlokProps(ProductUspGroup),
		},
		nodeResolvers: {
			paragraph: (children: React.ReactNode) => (
				<Paragraph size={meta?.textSize} marginBottom={meta?.textMarginBottom} addContrast={meta?.addContrast}>
					{children}
				</Paragraph>
			),
			heading: (children: string, { level }: any) => {
				const applyArticleSize = meta?.layout === 'article';
				if (level === 2) {
					return <Heading as="h2" title={children} exo2={meta?.exo2} articleSize={applyArticleSize} />;
				}
				if (level === 3) {
					return <Heading as="h3" title={children} exo2={meta?.exo2} articleSize={applyArticleSize} />;
				}
				if (level === 4) {
					return <Heading as="h4" title={children} exo2={meta?.exo2} articleSize={applyArticleSize} />;
				}
				if (level === 5) {
					return <Heading as="h5" title={children} exo2={meta?.exo2} articleSize={applyArticleSize} />;
				}
				if (level === 6) {
					return <Heading as="h6" title={children} exo2={meta?.exo2} articleSize={applyArticleSize} />;
				}
			},
			bullet_list: (children: string) => {
				return <BulletList>{children}</BulletList>;
			},
			horizontal_rule: () => {
				return <Divider margin="xl" />;
			},
		},
		markResolvers: {
			[MARK_LINK]: (children: string, { href, target, linktype, rel, anchor }: any) => {
				return (
					<MarkLink href={href} target={target} linktype={linktype} rel={rel} anchor={anchor}>
						{children}
					</MarkLink>
				);
			},
		},
	});
