import { Carousel, Heading } from '@/atoms';
import { CustomerReview } from '@/contexts/Integration';
import styles from './CustomerReviews.module.scss';

interface Props {
	reviews: CustomerReview[];
}

const CustomerReviews: React.FC<Props> = ({ reviews }) => {
	const customerReviews = reviews.filter((review) => review.review);
	return (
		<Carousel
			className={styles.reviewCarousel}
			showIndicators={customerReviews.length > 1}
			showArrows={true}
			centerSlidePercentage={100}
		>
			{customerReviews.map((review: CustomerReview, index: number) => {
				return (
					<div key={`customerReview-${index}`} className={styles.reviewItem}>
						<Heading as="h3" title={'Så här säger kunderna'} className={styles.reviewHeading} />
						<div className={styles.reviewText}>
							<div className={styles.review}>{review.review}</div>
							<div>{review.role}</div>
							<div>- {review.companyName}</div>
						</div>
					</div>
				);
			})}
		</Carousel>
	);
};

export default CustomerReviews;
