import { Blok } from '@/bloks/Blok';
import { ProductUspGroupStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { ProductUsp, ProductUspProps } from '../ProductUsp/ProductUsp';
import styles from './ProductUspGroup.module.scss';

interface BlokProps {
	blok: ProductUspGroupStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	usps: blok.usps?.map((usp) => ProductUsp.blokProps({ blok: usp })),
	antiUsps: blok.antiUsps?.map((antiUsp) => ProductUsp.blokProps({ blok: antiUsp })),
	_editable: blok._editable,
});

interface Props {
	usps: ProductUspProps[];
	antiUsps?: ProductUspProps[];
	className?: string;
	_editable?: string;
}

export const ProductUspGroup: Blok<Props, BlokProps> = ({ usps, antiUsps, className, _editable }) => {
	return (
		<ul {...editableBlok({ _editable })} className={cls(styles.container, className)}>
			{usps?.map((usp) => <ProductUsp {...usp} key={usp._uid} />)}
			{antiUsps?.map((usp) => <ProductUsp {...usp} anti key={usp._uid} />)}
		</ul>
	);
};

ProductUspGroup.blokProps = blokProps;
