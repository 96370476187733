import { ContainerFluid, Section } from '@/atoms';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { GlobalUspSmallGroup } from '@/bloks/Usp';
import { GlobalUspSmallGroupStoryblok, SectionUspStoryblok, ThemeV2Storyblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ISbStoryData } from '@/types/storyblok';
import React, { ReactNode } from 'react';

interface BlokProps {
	blok: SectionUspStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
	globalUspGroup: blok?.globalUspGroup as unknown as ISbStoryData<GlobalUspSmallGroupStoryblok>,
	theme: blok?.theme?.[0]?.theme,
	zone: meta?.zone,
});

interface Props {
	header?: HeaderCommonProps;
	globalUspGroup?: ReactNode[] | any;
	theme?: ThemeV2Storyblok['theme'];
	zone?: number;
}

export const SectionUsp = ({ header, globalUspGroup, theme, zone }: Props) => {
	const contentPiece = header?.titlePart2 ? `${header.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	const globalUsps = globalUspGroup?.content && GlobalUspSmallGroup.blokProps({ blok: globalUspGroup?.content });

	return (
		<ImpressionTrackingProvider
			value={{ contentName: `${zone}|SectionUsp`, contentPiece, contentTarget: 'no interactions possible' }}
		>
			<ContainerFluid theme={theme}>
				<Section>
					{header && <HeaderCommon {...header} marginBottom="3xl" />}
					{globalUsps && <GlobalUspSmallGroup {...globalUsps} />}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionUsp.blokProps = blokProps;
