import { cls } from '@/utils';
import Image from 'next/image';
import { CSSProperties } from 'react';
import styles from './ImageAsset.module.scss';
import { ImageAssetFilters, resizeStoryBlokImage } from './utils';

export interface ImageAssetProps {
	id: number;
	alt?: string;
	focus?: string | null;
	title?: string;
	filename: string;
	priority?: boolean;
	loading?: 'eager' | 'lazy' | undefined;
}

type ImageResize = `${number}x${number}` | `${number}x0` | `0x${number}`;

interface Props extends Pick<CSSProperties, 'objectFit' | 'objectPosition'> {
	absolute?: boolean;
	fill?: boolean;
	/**
	 * Storyblok image asset object
	 */
	asset: ImageAssetProps;
	testID?: string;
	/**
	 * If the image should be resized
	 *
	 * Examples: 100x100, 100x0, 0x100
	 *
	 * https://www.storyblok.com/docs/image-service
	 */
	resize?: ImageResize;
	/**
	 * Specify "smart" or a list of filters like ['quality(10)']
	 *
	 * - Smart can only be used in combination with resize
	 * - Smart is automatically replaced with resize(${focus}) if asset has a focus point
	 *
	 * https://www.storyblok.com/docs/image-service
	 */
	filters?: ImageAssetFilters;
	/**
	 * When true, the image will be considered high priority and preload.
	 * - Lazy loading is automatically disabled for images using priority.
	 * - For use in images above fold only!
	 *
	 *
	 */
	priority?: boolean;
	loading?: 'eager' | 'lazy' | undefined;
	className?: string;
	style?: CSSProperties;
}

/**
 * FDS Web Image component: Next.js <Image /> + Storyblok Image Service
 *
 * - https://nextjs.org/docs/api-reference/next/image
 * - https://www.storyblok.com/docs/image-service
 */
export const ImageAsset: React.FC<Props> = ({
	absolute,
	asset,
	className,
	filters,
	objectFit = 'cover',
	objectPosition = 'center',
	priority = false,
	loading,
	resize,
	testID,
	style,
}) => {
	if (!asset.filename) {
		if (process.env.NODE_ENV !== 'production') {
			console.warn('ImageAsset with asset missing filename invalid url');
		}
		return null;
	}

	const imageProps = resizeStoryBlokImage(asset.filename, {
		resize,
		filters,
		focus: asset.focus,
		objectFit,
		objectPosition,
		style,
	});

	return (
		<div
			className={cls(styles.container, objectFit === 'cover' && styles.cover, absolute && styles.absolute, className)}
		>
			<Image priority={priority} loading={loading} {...imageProps} alt={asset.alt!} data-testid={testID} />
		</div>
	);
};
