import { Button, ContainerFluid, Grid, Heading, Icon, TextField, Tooltip } from '@/atoms';
import { CalculatorDescription } from '@/bloks/Calculator/CalculatorDescription';
import { ChartData } from '@/bloks/Calculator/ProfitMarginCalculator';
import { CoverageRateCalculatorStoryblok } from '@/components';
import { formatMoney } from '@/utils';
import { handleCalculatorSubmitEvent } from '@/utils/piwik';
import { Chart, registerables } from 'chart.js';
import React, { FormEvent, useEffect, useState } from 'react';
import cls from 'classnames';
import { t } from 'ttag';
import styles from '../CalculatorStyling.module.scss';

interface CoverageRateInfo {
	salesRevenue?: number;
	variableCost?: number;
	coverageContribution?: number;
	coveragePercent?: number;
}

interface CoverageRateState {
	salesRevenue: number;
	variableCost: number;
}

interface Props {
	blok: CoverageRateCalculatorStoryblok;
}

export const CoverageRateCalculator: React.FC<Props> = ({ blok }) => {
	const [coverageRateState, setCoverageRateState] = useState<CoverageRateState>({
		salesRevenue: 0,
		variableCost: 0,
	});
	const [coverageRateInfo, setCoverageRateInfo] = useState<CoverageRateInfo>({
		salesRevenue: undefined,
		variableCost: undefined,
		coverageContribution: undefined,
		coveragePercent: undefined,
	});

	if (registerables) {
		Chart.register(...registerables);
	}

	const resetCoverageRateInfo = ({ salesRevenue, variableCost }: CoverageRateState) => {
		if (salesRevenue == 0 && variableCost == 0) {
			setCoverageRateInfo({});
		}
	};

	useEffect(() => {
		const elem = document.getElementById('CoverageRateChart') as HTMLCanvasElement;

		if (!elem) {
			return;
		}
		const chartData: Array<ChartData> = [
			{
				label: t`Försäljningsintäkt`,
				value: coverageRateInfo.coveragePercent || 0,
				color: '#FE85B5',
			},
			{
				label: t`Rörlig kostnad`,
				value: (coverageRateInfo.coveragePercent && 100 - coverageRateInfo.coveragePercent) || 0,
				color: '#DDE1E0',
			},
		];

		const emptyChartData = [
			{
				value: 100,
				color: '#DDE1E0',
			},
		];

		const emptyChartDataDataset = [
			{
				data: emptyChartData.map((data) => data.value.toFixed(1)),
				backgroundColor: emptyChartData.map((data) => data.color),
				hoverOffset: 0,
			},
		];

		chartData.sort((a, b) => b.value - a.value);

		const enableChart = coverageRateInfo.coveragePercent && coverageRateInfo.coveragePercent >= 0;

		const chart = new Chart(elem, {
			type: 'pie',
			data: {
				labels: chartData.map((data) => data.label),
				datasets: enableChart
					? [
							{
								data: chartData.map((data) => data.value.toFixed(1)),
								backgroundColor: chartData.map((data) => data.color),
								hoverOffset: 25,
							},
						]
					: emptyChartDataDataset,
			},
			options: {
				elements: {
					arc: {
						borderWidth: 0,
					},
				},
				layout: {
					padding: {
						bottom: 25,
						top: 25,
						left: 25,
						right: 25,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							label: (item) => `${item.label}: ${item.formattedValue}%`,
						},
					},
				},
				events: enableChart ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'] : [],
			},
		});

		return () => {
			chart.destroy();
		};
	}, [coverageRateInfo]);

	function handleSubmit(event: FormEvent) {
		event.preventDefault();

		const salesRevenue = coverageRateState.salesRevenue;
		const variableCost = coverageRateState.variableCost;
		const coverageContributionCost = salesRevenue - variableCost;
		const checkIfNumber = isNaN(salesRevenue);

		handleCalculatorSubmitEvent('CoverageRateCalculator');

		setCoverageRateInfo({
			salesRevenue,
			variableCost,
			coverageContribution: coverageContributionCost,
			coveragePercent: checkIfNumber || salesRevenue == 0 ? undefined : (coverageContributionCost / salesRevenue) * 100,
		});
	}

	const CalculatorResult = () => (
		<div className={styles.description}>
			<Heading as="div" title={t`Täckningsbidrag`} size="h5" marginBottom="sm" />
			<Heading
				className={styles.coverageContribution}
				as="div"
				title={`${formatMoney(coverageRateInfo.coverageContribution || '')} kr`}
				size="h2"
				marginBottom="sm"
			/>

			{coverageRateInfo.coveragePercent != undefined && (
				<>
					<Heading as="div" title={t`Täckningsgrad`} size="h5" marginBottom="sm" />

					<div className={styles.coverageRateChart}>
						<div>
							<canvas id="CoverageRateChart" />
						</div>
						<Heading
							className={styles.coverageContribution}
							as="div"
							title={`${coverageRateInfo.coveragePercent.toLocaleString(undefined, {
								maximumFractionDigits: 1,
							})} %`}
							size="h2"
							marginBottom="sm"
						/>
					</div>
				</>
			)}
		</div>
	);
	const getCalculatorResult = () => {
		if (coverageRateInfo.coverageContribution != undefined) {
			return <CalculatorResult />;
		}
		return <CalculatorDescription blok={blok} />;
	};

	return (
		<ContainerFluid marginBottom="3xl">
			<Grid columns={{ base: 1, lg: 2 }}>
				<Grid.Item className={styles.calculator}>
					<form onSubmit={handleSubmit} className={styles.form}>
						<div>
							<div className={cls(styles.headingRow, styles.coverageRateHeading)}>
								<div>
									<Icon name="calculator" />
								</div>
								<Heading
									className={styles.heading}
									as="div"
									title={t`Täckningsbidrag & täckningsgrad`}
									size="h5"
									marginBottom="none"
								/>
							</div>
							<div className={styles.textField}>
								<div className={styles.textFieldHeading}>
									<Heading title={t`Försäljningsintäkt - kr`} as="div" size="h6" />
									<Tooltip
										theme="darkGreen"
										className={styles.toolTip}
										text={blok.salesRevenueTooltip[0]?.text ?? ''}
									/>
								</div>
								<TextField
									id="coverage-rate_sales-revenue-field"
									title={t`Försäljningsintäkt - kr`}
									testID="coverage-rate_sales-revenue-field"
									type="number"
									value={coverageRateState.salesRevenue == 0 ? '' : coverageRateState.salesRevenue}
									onChange={(event) => {
										const state = {
											...coverageRateState,
											salesRevenue: event.target.value,
										};
										setCoverageRateState(state);
										resetCoverageRateInfo(state);
									}}
									required
									className={styles.numberInputFields}
								/>
							</div>
							<div className={styles.textField}>
								<div className={styles.textFieldHeading}>
									<Heading title={t`Rörlig kostnad - kr`} as="div" marginBottom="md" size="h6" />
									<Tooltip
										theme="darkGreen"
										className={styles.toolTip}
										text={blok.variableCostTooltip[0]?.text ?? ''}
									/>
								</div>
								<TextField
									id="coverage-rate_variable-cost-field"
									title={t`Rörlig kostnad - kr`}
									testID="coverage-rate_variable-cost-field"
									type="number"
									value={coverageRateState.variableCost == 0 ? '' : coverageRateState.variableCost}
									onChange={(event) => {
										const state = {
											...coverageRateState,
											variableCost: event.target.value,
										};
										setCoverageRateState(state);
										resetCoverageRateInfo(state);
									}}
									required
									className={styles.numberInputFields}
								/>
							</div>
						</div>
						<div>
							<Button
								type="submit"
								testID="coverage-rate_compute-button"
								size="large"
								className={styles.calculateButton}
								disabled={coverageRateState.variableCost == 0 || coverageRateState.salesRevenue == 0}
							>{t`Beräkna`}</Button>

							{coverageRateState.salesRevenue != 0 && (
								<div className={styles.clearFieldsBtnContainer}>
									<button
										type="button"
										data-test-id="coverage-rate_clear-fields"
										className={styles.clearFieldsButton}
										onClick={() => {
											setCoverageRateState({
												salesRevenue: 0,
												variableCost: 0,
											});
											setCoverageRateInfo({
												salesRevenue: undefined,
												variableCost: undefined,
												coverageContribution: undefined,
												coveragePercent: undefined,
											});
										}}
									>{t`Rensa`}</button>
								</div>
							)}
						</div>
					</form>
				</Grid.Item>
				<Grid.Item>{getCalculatorResult()}</Grid.Item>
			</Grid>
		</ContainerFluid>
	);
};
