import { DatasourceEntry } from '@/types';
import React, { createContext, useContext } from 'react';

/**
 * Provider of article tags
 */
export const useArticleTags = () => {
	return useContext(Context);
};

interface ArticleTagState {
	articleTags: { categories: DatasourceEntry[]; labels: DatasourceEntry[] };
}

const Context = createContext<ArticleTagState>({
	articleTags: { categories: [], labels: [] },
});

interface Props {
	children: React.ReactNode;
	value: { categories: DatasourceEntry[]; labels: DatasourceEntry[] };
}
export const ArticleTagsProvider: React.FC<Props> = ({ children, value }) => {
	return <Context.Provider value={{ articleTags: value }}>{children}</Context.Provider>;
};
