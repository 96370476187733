import { Link, LinkProps } from '@/atoms';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import { ComponentProps } from 'react';
import styles from './LinkAnchor.module.scss';

interface Props extends LinkProps, Pick<ComponentProps<'a'>, 'lang' | 'rel' | 'target' | 'title'> {
	children: React.ReactNode;
	className?: string;
	hoverEffect?: boolean;
	tabIndex?: number;
}
/**
 * Use this instead of Link from next/link
 * It is the same thing, but it sets locale="sv" by default, otherwise all links
 * from an english page will link all other pages to be english too, which is
 * not so nice when there's very few english pages.
 *
 * A hover effect can optionally be applied.
 */
export const LinkAnchor: React.FC<Props> = ({ className, locale = 'sv', hoverEffect, tabIndex = 0, ...rest }) => {
	const themeInfo = useTheme();

	return hoverEffect ? (
		<span className={cls(className, hoverEffect && styles.hoverEffect)}>
			<Link
				locale={locale}
				{...rest}
				className={cls(themeInfo?.styles?.textLink)}
				tabIndex={tabIndex}
				prefetch={false}
			/>
		</span>
	) : (
		<Link
			locale={locale}
			{...rest}
			className={cls(themeInfo?.styles?.textLink, className)}
			tabIndex={tabIndex}
			prefetch={false}
		/>
	);
};
