import { Icon, IconButton } from '@/atoms';
import { cls } from '@/utils';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton } from 'next-share';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from './SocialMedia.module.scss';

interface Props {
	title: string;
	background?: boolean;
	className?: string;
}

export function SocialMedia({ title, background, className }: Props) {
	const pathname = usePathname();
	const [copied, setCopied] = useState(false);

	const url = `https://www.fortnox.se${pathname}`;

	useEffect(() => {
		const timeout = setTimeout(() => {
			setCopied(false);
		}, 2000);
		if (copied && navigator.clipboard) {
			navigator.clipboard.writeText(url);
			setCopied(true);
		}

		return () => clearTimeout(timeout);
	}, [copied, url]);

	return (
		<div className={cls(styles.container, background && styles.background, className)}>
			<div className={styles.socialMediaItem}>
				<LinkedinShareButton url={url}>
					<Icon
						name="linkedin-in"
						type="brand"
						color={background ? '--text-dark' : '--read-only'}
						size={background ? '1x' : undefined}
					/>
				</LinkedinShareButton>
			</div>
			<div className={styles.socialMediaItem}>
				<FacebookShareButton url={url} quote={title}>
					<Icon
						className={styles.facebook}
						name="facebook-f"
						type="brand"
						color={background ? '--text-dark' : '--read-only'}
						size={background ? '1x' : undefined}
					/>
				</FacebookShareButton>
			</div>
			<div className={styles.socialMediaItem}>
				<EmailShareButton url={url} subject={title}>
					<Icon
						name="envelope"
						type="solid"
						color={background ? '--text-dark' : '--read-only'}
						size={background ? '1x' : undefined}
					/>
				</EmailShareButton>
			</div>
			<div className={styles.socialMediaItem}>
				<div className={styles.iconWrapper} onClick={() => setCopied(true)}>
					<IconButton
						aria-label={t`Kopiera länk`}
						className={cls(styles.icon, copied && styles.copied)}
						iconName={copied ? 'check' : 'link'}
						iconColor={copied ? '--primary-green' : undefined}
					/>
				</div>
			</div>
		</div>
	);
}

export default SocialMedia;
