import { Heading, Icon, Text } from '@/atoms';
import { CareerButton, LinkButton } from '@/bloks/Button';
import { ThemeCareerStoryblok } from '@/components';
import { useJobDepartment, useJobLocations } from '@/contexts/teamtailor/TeamtailorProvider';
import { t } from 'ttag';
import styles from './JobItem.module.scss';

interface Props {
	title: string;
	departmentId?: string | null;
	locationIds?: string[] | null;
	id: string;
}

const theme: Record<string, ThemeCareerStoryblok['theme']> = {
	'99468': 'development', // Development
	'99469': 'careerSupport', // Support
	'99470': 'product', // Product
	'99471': 'sales', // Sales
	'99473': 'marketing', // Marketing & Communication
	'196412': 'businessSupport', // Business support
} as const;

const departmentUrls: Record<string, string> = {
	'99468': '/karriar/utveckling', // Development
	'99469': '/karriar/support', // Support
	'99470': '/karriar/produkt', // Product
	'99471': '/karriar/salj', // Sales
	'99473': '/karriar/marknad-kommunikation', // Marketing & Communication
	'196412': '/karriar/affarsstod', //  Business support
} as const;

/**
 * A card that displays a summary of a job ad and links to the page for the full job ad.
 *
 * Used in SectionJobList
 */
export const JobItem: React.FC<Props> = ({ title, locationIds, departmentId, id }) => {
	const [department] = useJobDepartment(departmentId ?? '');
	const locations = useJobLocations(locationIds ?? [])?.join(' | ');
	const btnTheme = departmentId ? (theme[departmentId] ?? 'white') : 'white';
	const departmentUrl = departmentId && departmentUrls[departmentId];

	return (
		<div className={styles.container}>
			<div>
				<Heading as="div" size="h5" title={title} />
				<div className={styles.infoRow}>
					{departmentUrl && department && (
						<CareerButton
							text={department}
							link={{
								cached_url: departmentUrl,
								linktype: 'story',
							}}
							theme={btnTheme}
							size="small"
						/>
					)}
					<Text marginBottom="none" muted>
						{locations}
					</Text>
				</div>
			</div>
			<LinkButton
				href={`/karriar/lediga-jobb/jobb/${id}`}
				variant="text"
				icon={<Icon name="arrow-right" size="1x" color="--text-primary-green" />}
				className={styles.btn}
			>
				{t`Läs mer och ansök`}
			</LinkButton>
		</div>
	);
};
