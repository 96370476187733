import { BrandLine, Grid, Heading, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { LinkButtonStoryblok, RichtextStoryblok, TextTwoColumnsStoryblok, ThemeV2Storyblok } from '@/components';
import { cls } from '@/utils';
import React from 'react';
import styles from './TextTwoColumns.module.scss';

interface BlokProps {
	blok: TextTwoColumnsStoryblok;
	meta: {
		theme: ThemeV2Storyblok['theme'];
	};
}

interface TextTwoColumnsProps {
	title?: string;
	leftColumn: RichtextStoryblok;
	rightColumn: RichtextStoryblok;
	linkButton?: LinkButtonStoryblok[];
	theme: ThemeV2Storyblok['theme'];
	buttonVariant?: boolean;
}

const blokProps = ({ blok, meta }: BlokProps): TextTwoColumnsProps => ({
	title: blok.title,
	leftColumn: blok.leftColumn,
	rightColumn: blok.rightColumn,
	linkButton: blok.linkButton,
	theme: meta?.theme,
	buttonVariant: blok.buttonVariant,
});

export const TextTwoColumns = ({
	title,
	leftColumn,
	rightColumn,
	linkButton,
	theme,
	buttonVariant = false,
}: TextTwoColumnsProps) => {
	return (
		<div className={styles.contentContainer}>
			{title && (
				<>
					<BrandLine className={styles.brandLine} />
					<Heading as="div" size="h4" title={`${title}`} marginBottom={'xl'} />
				</>
			)}
			<Grid
				className={cls(styles.textGrid, styles[`theme--${theme}`], buttonVariant && styles.marginBottom)}
				columns={{ base: 1, md: 2 }}
				colGap="4xl"
			>
				<Grid.Item>
					<Text as="div" marginBottom="none">
						{renderRichText(leftColumn)}
					</Text>
				</Grid.Item>
				<Grid.Item>
					<Text as="div" marginBottom="none">
						{renderRichText(rightColumn)}
					</Text>
				</Grid.Item>
			</Grid>
			{linkButton?.map((link) => (
				<LinkButton
					key={link._uid}
					{...LinkButton.blokProps({ blok: link })}
					variant={buttonVariant ? 'filled' : 'text'}
				>
					{link.text}
				</LinkButton>
			))}
		</div>
	);
};

TextTwoColumns.blokProps = blokProps;
