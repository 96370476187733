import { useEffect, useRef, useState } from 'react';

/**
 * Hook to determine the scroll direction of the user.
 */
export const useScrollDirection = (initialScrollPos = 0, scrollThreshold = 30, topThreshold = 50) => {
	const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
	const [prevScrollPos, setPrevScrollPos] = useState(initialScrollPos);
	const ticking = useRef(false);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;

			if (!ticking.current) {
				window.requestAnimationFrame(() => {
					const topOffset = document.body.scrollTop > topThreshold || document.documentElement.scrollTop > topThreshold;
					const scrollOffset = Math.abs(currentScrollPos - prevScrollPos) > scrollThreshold;

					if (topOffset && scrollOffset) {
						const direction = prevScrollPos < currentScrollPos ? 'down' : 'up';

						setScrollDirection(direction);
						setPrevScrollPos(currentScrollPos);
					}
					ticking.current = false;
				});

				ticking.current = true;
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [prevScrollPos, scrollThreshold, topThreshold]);

	return { scrollDirection };
};

export default useScrollDirection;
