import { TextImageGroupStoryblok, TextImageStoryblok, ThemeV2Storyblok } from '@/components';
import React from 'react';
import { TextImage } from '../TextImage/TextImage';

interface BlokProps {
	blok: TextImageGroupStoryblok;
	meta: {
		theme: ThemeV2Storyblok['theme'];
	};
}

interface TextImageGroupProps {
	group: TextImageStoryblok[];
	theme: ThemeV2Storyblok['theme'];
}

const blokProps = ({ blok, meta }: BlokProps): TextImageGroupProps => ({
	group: blok.textImage,
	theme: meta?.theme,
});

export const TextImageGroup = ({ group, theme }: TextImageGroupProps) => {
	return (
		<>
			{group?.map((textImage) => (
				<TextImage
					key={textImage._uid}
					theme={theme}
					title={textImage.title}
					text={textImage.text}
					image={textImage.image}
					linkButton={textImage.linkButton}
					buttonVariant={textImage.buttonVariant}
				/>
			))}
		</>
	);
};

TextImageGroup.blokProps = blokProps;
