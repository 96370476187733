import { BrandLine, Card, Heading } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderCommon, HeaderCommonProps, ImageCaption } from '@/bloks/Other';
import { ImageCaptionProps } from '@/bloks/Other/ImageCaption/ImageCaption';
import { CardLargeInvertedStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useTheme } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './CardLargeInverted.module.scss';

interface BlokProps {
	blok: CardLargeInvertedStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): CardLargeInvertedProps => ({
	image: blok.image,
	header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header[0] }),
	layout: blok?.layout,
	imageCaption: blok.imageCaption?.[0] && ImageCaption.blokProps({ blok: blok.imageCaption[0] }),
	_editable: blok._editable,
	zone: meta?.zone,
});

export interface CardLargeInvertedProps {
	image: ImageAssetProps;
	header: HeaderCommonProps;
	layout?: 'text-left' | 'text-right';
	imageCaption?: ImageCaptionProps;
	_editable?: string;
	zone?: number;
}

export const CardLargeInverted: Blok<CardLargeInvertedProps, BlokProps> = ({
	image,
	header,
	layout,
	imageCaption,
	_editable,
	zone,
}) => {
	const themeInfo = useTheme();
	const title = header?.titlePart2 ? `${header?.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	let contentTarget = header?.linkButtons?.length ? 'multiple interactions possible' : 'no interactions possible';
	if (header?.linkButtons?.length === 1) contentTarget = 'one interaction possible';
	return (
		<ImpressionTrackingProvider value={{ contentName: `${zone}|SectionCard`, contentPiece: title, contentTarget }}>
			<div
				className={cls(styles.container, styles[`layout--${layout}`], themeInfo.styles.bgColor)}
				{...editableBlok({ _editable })}
			>
				{image?.filename && (
					<div className={styles.backgroundImage}>
						<ImageAsset absolute asset={image} objectFit="cover" fill objectPosition="center center" filters="smart" />
						<div className={styles.titleMobile}>
							{header?.smallTitle && (
								<Heading
									as="div"
									size="h6"
									title={header.smallTitle}
									data-testid="section-common-header-h6"
									marginBottom="xl"
									smallTitle
									addContrast
									noId
								/>
							)}
							{header?.titlePart1 && (
								<Heading
									as="div"
									size={header.titleSize}
									title={header.titlePart1}
									titlePart2={header.titlePart2}
									marginBottom="xl"
									addContrast
									noId
								/>
							)}
						</div>
						<div className={styles.overlay} />
					</div>
				)}

				<Card
					className={cls(styles.textContainer, themeInfo.styles.bgColor)}
					border={false}
					rounded={false}
					padding="custom"
				>
					<div className={styles.headerCommon}>
						{!header?.hideBrandline && (
							<BrandLine className={cls(styles.brandLine, themeInfo.styles.brandlineColor)} marginBottom="xl" />
						)}
						{header?.smallTitle && (
							<Heading
								as="div"
								size="h6"
								title={header.smallTitle}
								marginBottom="xl"
								smallTitle
								className={styles.titleDesktop}
							/>
						)}
						{header?.titlePart1 && (
							<Heading
								as="div"
								size={header.titleSize}
								title={header.titlePart1}
								titlePart2={header.titlePart2}
								marginBottom="xl"
								className={styles.titleDesktop}
							/>
						)}
						{header && <HeaderCommon {...header} hideBrandline smallTitle="" titlePart1="" titlePart2="" />}
					</div>
				</Card>

				{imageCaption && (
					<div className={styles.imageCaption}>
						<ImageCaption {...imageCaption} />
					</div>
				)}
			</div>
		</ImpressionTrackingProvider>
	);
};

CardLargeInverted.blokProps = blokProps;
