import { Button, ContainerFluid, Grid, Heading, Icon, Select, SelectOption, TextField, Tooltip } from '@/atoms';
import { CalculatorDescription } from '@/bloks/Calculator/CalculatorDescription';
import { ChartData } from '@/bloks/Calculator/ProfitMarginCalculator';
import { EquityCalculatorStoryblok } from '@/components';
import { handleCalculatorSubmitEvent } from '@/utils/piwik';
import { Chart, registerables } from 'chart.js';
import React, { FormEvent, useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from '../CalculatorStyling.module.scss';

interface EquityInfo {
	percent?: number;
}

interface EquityState {
	formattedEquityCapital: any;
	formattedTotalCapital: any;
	formattedUntaxedReserves: any;
	taxRate: number;
}

interface Props {
	blok: EquityCalculatorStoryblok;
}

export const EquityCalculator: React.FC<Props> = ({ blok }) => {
	const equityTaxRates: SelectOption[] = [
		{ value: '0.206', label: t`2021-2023: (20,6%)`, id: 'currentTaxRate' },
		{ value: '0.214', label: t`2019-2020: (21,4%)`, id: 'oldTaxRates' },
	];
	const [equityState, setEquityState] = useState<EquityState>({
		formattedEquityCapital: '',
		formattedTotalCapital: '',
		formattedUntaxedReserves: '',
		taxRate: parseFloat(equityTaxRates[0].value),
	});
	const [equityInfo, setEquityInfo] = useState<EquityInfo>({
		percent: undefined,
	});
	const untaxedReserves = parseInt(equityState.formattedUntaxedReserves.replace(/\D/g, ''));
	const equityCapital = parseInt(equityState.formattedEquityCapital.replace(/\D/g, ''));
	const totalCapital = parseInt(equityState.formattedTotalCapital.replace(/\D/g, ''));

	if (registerables) {
		Chart.register(...registerables);
	}

	const resetEquityInfo = ({
		formattedEquityCapital,
		formattedUntaxedReserves,
		formattedTotalCapital,
	}: EquityState) => {
		if (formattedEquityCapital == '' && formattedUntaxedReserves == '' && formattedTotalCapital == '') {
			setEquityInfo({});
		}
	};

	useEffect(() => {
		const el = document.getElementById('equityChart') as HTMLCanvasElement;

		if (!el) {
			return;
		}
		const chartData: Array<ChartData> = [
			{
				label: t` Eget kapital`,
				value: equityInfo.percent || 0,
				color: '#FE85B5',
			},
			{
				label: t` Total kapital`,
				value: equityInfo.percent ? 100 - equityInfo.percent : 0,
				color: '#DDE1E0',
			},
		];
		chartData.sort((a, b) => b.value + a.value);

		const chart = new Chart(el, {
			type: 'pie',
			data: {
				labels: chartData.map((data) => data.label),
				datasets: [
					{
						data: chartData.map((data) => data.value.toFixed(1)),
						backgroundColor: chartData.map((data) => data.color),
						hoverOffset: 25,
					},
				],
			},
			options: {
				elements: {
					arc: {
						borderWidth: 0,
					},
				},
				layout: {
					padding: {
						bottom: 25,
						top: 25,
						left: 25,
						right: 25,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							label: (item) => `${item.label}: ${item.formattedValue}%`,
						},
					},
				},
			},
		});

		return () => {
			chart.destroy();
		};
	}, [equityInfo]);

	function handleSubmit(event: FormEvent) {
		event.preventDefault();

		handleCalculatorSubmitEvent('EquityCalculator');

		const taxCalculation = (1 - equityState.taxRate) * untaxedReserves;
		const adjustedCapital = equityCapital + taxCalculation;

		setEquityInfo({
			percent: (adjustedCapital / totalCapital) * 100,
		});
	}

	const validateEquity = (): true | string => {
		if (untaxedReserves + equityCapital > totalCapital) {
			return t`Eget kapital + obeskattade reserver får inte överstiga totalt kapital.`;
		}
		return true;
	};

	const CalculatorResult = () => (
		<div className={styles.description}>
			<Heading as="div" title={t`Soliditet`} size="h5" marginBottom="sm" />
			<div className={styles.chart}>
				<div>
					<canvas id="equityChart" />
					<div className={styles.percent}>
						<p>{`${equityInfo.percent?.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`}</p>
					</div>
				</div>
			</div>
		</div>
	);

	const getCalculatorResult = () => {
		if (equityInfo.percent != undefined) {
			return <CalculatorResult />;
		}
		return <CalculatorDescription blok={blok} />;
	};

	return (
		<ContainerFluid marginBottom="3xl">
			<Grid columns={{ base: 1, lg: 2 }}>
				<Grid.Item className={styles.calculator}>
					<div className={styles.headingRow}>
						<Icon name="calculator" />
						<Heading className={styles.heading} as="div" title={t`Soliditet`} size="h5" marginBottom="none" />
					</div>
					<form onSubmit={handleSubmit}>
						<div className={styles.textField}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Eget kapital - kr`} as="div" size="h6" />
								<Tooltip theme="darkGreen" className={styles.toolTip} text={blok.equityCapitalTooltip[0]?.text ?? ''} />
							</div>
							<TextField
								id="equity-calculator_equity-capital-field"
								title={t`Eget kapital`}
								testID="equity-calculator_equity-capital-field"
								type="number"
								value={equityState.formattedEquityCapital}
								onChange={(event) => {
									const state = { ...equityState, formattedEquityCapital: event.target.value };
									setEquityState(state);
									resetEquityInfo(state);
								}}
								required
								validators={validateEquity}
								className={styles.numberInputFields}
							/>
						</div>
						<div className={styles.textField}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Totalt kapital - kr`} as="div" marginBottom="md" size="h6" />
								<Tooltip theme="darkGreen" className={styles.toolTip} text={blok.totalCapitalTooltip[0]?.text ?? ''} />
							</div>
							<TextField
								id="equity-calculator_total-capital-field"
								title={t`Totalt kapital`}
								testID="equity-calculator_total-capital-field"
								type="number"
								value={equityState.formattedTotalCapital}
								onChange={(event) => {
									const state = { ...equityState, formattedTotalCapital: event.target.value };
									setEquityState(state);
									resetEquityInfo(state);
								}}
								validators={validateEquity}
								required
								className={styles.numberInputFields}
							/>
						</div>
						<div className={styles.textField}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Obeskattade reserver - kr`} as="div" marginBottom="md" size="h6" />
								<Tooltip
									theme="darkGreen"
									className={styles.toolTip}
									text={blok.untaxedReservesTooltip[0]?.text ?? ''}
								/>
							</div>
							<TextField
								id="equity-calculator_untaxed-reserves-field"
								title={t`Obeskattade reserver`}
								testID="equity-calculator_untaxed-reserves-field"
								type="number"
								value={equityState.formattedUntaxedReserves}
								onChange={(event) => {
									const state = { ...equityState, formattedUntaxedReserves: event.target.value };
									setEquityState(state);
									resetEquityInfo(state);
								}}
								validators={validateEquity}
								required
								className={styles.numberInputFields}
							/>
						</div>
						<div className={styles.selectField}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Skattesats`} as="div" marginBottom="md" size="h6" />
								<Tooltip theme="darkGreen" className={styles.toolTip} text={blok.equityTaxRateTooltip[0]?.text ?? ''} />
							</div>

							<Select
								data={equityTaxRates}
								defaultValue={equityTaxRates[0]}
								title={t`Välj skattesats` + ' *'}
								aria-label={t`Välj skattesats`}
								testID="equity-calculator_tax-rate"
								onSelect={(value) => setEquityState({ ...equityState, taxRate: parseFloat(value) })}
								id={'equityTaxRateSelector'}
							/>
						</div>
						<Button
							type="submit"
							size="large"
							testID="equity-calculator_compute-button"
							className={styles.calculateButton}
							disabled={
								equityState.formattedEquityCapital == 0 ||
								equityState.formattedTotalCapital == 0 ||
								validateEquity() != true
							}
						>{t`Beräkna`}</Button>
						{equityState.formattedEquityCapital != '' && equityState.formattedTotalCapital != '' && (
							<div className={styles.clearFieldsBtnContainer}>
								<button
									type="button"
									data-test-id="equity-calculator_clear-fields"
									className={styles.clearFieldsButton}
									onClick={() => {
										setEquityState({
											formattedEquityCapital: '',
											formattedTotalCapital: '',
											formattedUntaxedReserves: '',
											taxRate: parseFloat(equityTaxRates[0].value),
										});
										setEquityInfo({
											percent: undefined,
										});
									}}
								>{t`Rensa`}</button>
							</div>
						)}
					</form>
				</Grid.Item>
				<Grid.Item>{getCalculatorResult()}</Grid.Item>
			</Grid>
		</ContainerFluid>
	);
};
