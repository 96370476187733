import { Grid, Section, ShowMoreButton, Text } from '@/atoms';
import { ImageType, IntegrationClientType, IntegrationHit, IntegrationTransform } from '@/contexts/Integration';
import { useSearchIntegrations } from '@/contexts/Integration/searchIntegrationsContext';
import * as Sentry from '@sentry/nextjs';
import { t } from 'ttag';
import { IntegrationCard } from '../IntegrationCard/IntegrationCard';
import styles from '../NavigationPageIntegrations.module.scss';

const transformCategories = (
	category: string | string[],
): { categoryNameSv: string; categoryNameEn: string; categoryId: number }[] => {
	if (typeof category === 'string') {
		return [{ categoryNameSv: category, categoryNameEn: category, categoryId: 1 }];
	} else if (Array.isArray(category)) {
		return category.map((cat, index) => ({
			categoryNameSv: cat,
			categoryNameEn: cat,
			categoryId: index + 1,
		}));
	}
	return [];
};

const trimUrl = (url: string): string =>
	(() => {
		try {
			const parsedUrl = new URL(url);
			return parsedUrl.pathname;
		} catch (error) {
			Sentry.captureException(error);
			return '';
		}
	})();

const transformSearchHit = (hit: IntegrationHit): IntegrationTransform => {
	return {
		appId: hit.id,
		appName: hit.custom_fields?.appName ?? hit.title,
		searchAppName: hit.custom_fields?.searchAppName ?? hit.title,
		summaryTitle: hit.meta_description ?? '',
		summary: hit.meta_description ?? '',
		about: hit.meta_description ?? '',
		readMoreUrl: trimUrl(hit.url),
		urlSlug: trimUrl(hit.url),
		images: [{ path: hit.images.main, type: ImageType.ICON, order: 0 }],
		priceModel: { clientType: (hit.custom_fields?.priceModel as IntegrationClientType) ?? '', price: '0' },
		insights: { numberOfUsersRange: hit.custom_fields?.numberOfUsersRange ?? '0' },
		categories: transformCategories(hit.custom_fields?.category ?? ''),
		ratings: {
			averageScore: hit.custom_fields?.averageRating ?? 0,
			numberOfRatings: hit.custom_fields?.numberOfRatings ?? 0,
			weightedAverageScore: hit.custom_fields?.weightedAverageScore ?? 0,
		},
		tags: hit.custom_fields?.tags ?? [],
		contactInformation: {
			companyName: hit.custom_fields?.companyName ?? '',
		},
	};
};

export const SearchResult: React.FC = () => {
	const { searchResult, currentPage, setCurrentPage, hasMoreHits } = useSearchIntegrations();

	const loadNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	if (!searchResult.hits) {
		return null;
	}

	if (searchResult?.hits.length === 0) {
		return (
			<Section className={styles.notFoundText}>
				<Text>{t`Tyvärr hittade vi inga partnerappar som matchar din sökning.`}</Text>
			</Section>
		);
	}

	return (
		<>
			<Grid
				columns={{
					base: 1,
					md: 2,
					xl: 3,
				}}
				colGap="xl"
				rowGap="xl"
			>
				{searchResult?.hits.map((hit: IntegrationHit) => {
					return (
						<Grid.Item key={hit.id}>
							<IntegrationCard type="search" integration={transformSearchHit(hit)} />
						</Grid.Item>
					);
				})}
			</Grid>
			{hasMoreHits && <ShowMoreButton onClick={loadNextPage}>{t`Ladda fler integrationer`}</ShowMoreButton>}
		</>
	);
};
