import { ContainerFluid, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import { CardSupportProfileStoryblok, SectionProfileStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';

interface Props {
	blok: SectionProfileStoryblok;
	meta: { zone: number };
}

export function SectionProfile({ blok, meta }: Props) {
	const { items } = blok;
	const { hasSubMenuV1 } = useSubMenu();

	const cardSupportProfile = blok.cardSupportProfile as unknown as ISbStoryData<CardSupportProfileStoryblok>;

	return (
		<ImpressionTrackingProvider value={{ contentName: `${meta?.zone}|SectionProfile`, contentPiece: 'Kontakt' }}>
			<ContainerFluid>
				<Section {...editableBlok(blok)} paddingX={hasSubMenuV1 ? { base: 'md', xl: 'none' } : undefined}>
					{items?.map((item) => <DynamicComponent key={item._uid} blok={item} meta={{ ...meta, noPadding: true }} />)}
					{cardSupportProfile && cardSupportProfile.content && (
						<DynamicComponent
							key={cardSupportProfile.content._uid}
							blok={cardSupportProfile.content}
							meta={{ ...meta, noPadding: true }}
						/>
					)}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
}
