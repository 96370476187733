import { Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { VideoStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { RefObject } from 'react';
import { t } from 'ttag';
import styles from './Video.module.scss';

interface BlokProps {
	blok: VideoStoryblok;
}

const blokProps = ({ blok }: BlokProps): VideoProps => ({
	isEvent: blok.isEvent,
	id: blok.id,
	width: blok.width,
	caption: blok.caption,
	_editable: blok._editable,
});

export interface VideoProps {
	isEvent?: boolean;
	id: string;
	width?: '50' | '60' | '100';
	/**
	 * Autoplays video, the video will also be muted if autoplay is set
	 */
	autoplay?: boolean;
	/**
	 * Can only be used for vimeo videos
	 */
	hideControls?: boolean;
	loop?: boolean;
	/**
	 * Pauses video automatically if another video starts playing
	 * Only works for vimeo videos
	 * Is set to true by default
	 */
	autoPause?: boolean;
	caption?: string;
	_editable?: string;
	forwardedRef?: RefObject<HTMLIFrameElement>;
	className?: string;
}

const urlHandler = (
	videoId: string | number,
	isEvent: boolean,
	autoPlay: boolean,
	hideControls: boolean,
	loop: boolean,
	autoPause: boolean,
) => {
	let url = isEvent ? `https://vimeo.com/event/${videoId}/embed` : `https://player.vimeo.com/video/${videoId}`;
	if (autoPlay || hideControls || loop) url += '?';
	if (autoPlay) url += 'autoplay=1&muted=1';
	if (hideControls) url += '&controls=0';
	if (loop) url += '&loop=1';
	if (!autoPause) url += '&autopause=0';
	return url;
};

export const Video: Blok<VideoProps, BlokProps> = ({
	isEvent = false,
	id,
	width,
	autoplay = false,
	hideControls = false,
	loop = false,
	autoPause = true,
	caption,
	_editable,
	forwardedRef,
	className,
}) => {
	const url = urlHandler(id, isEvent, autoplay, hideControls, loop, autoPause);
	const { setTargetInteraction } = useImpressionTracking();
	return (
		<div
			{...editableBlok({ _editable })}
			className={styles[`width--${width}`]}
			onClick={() => {
				if (hideControls) return;
				setTargetInteraction({ contentTarget: 'Video' });
			}}
		>
			<div className={cls(styles.video, className)}>
				<iframe
					title={t`Fortnox Video`}
					loading="lazy"
					sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation"
					className={styles.responsive}
					src={url}
					allowFullScreen
					data-testid="video"
					ref={forwardedRef}
				/>
			</div>
			{caption && (
				<Text className={styles.caption} muted>
					{caption}
				</Text>
			)}
		</div>
	);
};

Video.blokProps = blokProps;
