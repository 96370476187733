import { Section } from '@/atoms';
import { renderRichText } from '@/bloks/RichText';
import { TableBlok } from '@/bloks/RichText/TableBlok';
import { SectionTableStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionTable.module.scss';

interface Props {
	blok: SectionTableStoryblok;
	meta?: {
		layout?: 'article';
	};
}

/**
 * Table Section
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=592%3A15014&mode=dev
 */
export const SectionTable: React.FC<Props> = ({ blok, meta }) => {
	const { text, leftTdIsLabel, containsCode } = blok;
	const table = blok.table.find((t) => t);
	const theme = blok.theme?.[0]?.theme ?? 'white';

	return (
		<Section
			{...editableBlok(blok)}
			paddingX={meta?.layout === 'article' ? { base: 'none' } : undefined}
			paddingY={meta?.layout === 'article' ? { base: 'none' } : undefined}
			className={cls(styles[`layout--${meta?.layout}`])}
		>
			{text && <div className={styles.textSection}>{renderRichText(text)}</div>}
			{table && (
				<TableBlok
					{...TableBlok.blokProps({
						blok: table,
						meta: { leftTdIsLabel, containsCode },
					})}
					theme={theme}
				/>
			)}
		</Section>
	);
};
