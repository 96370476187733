import { Card, Heading, Text } from '@/atoms';
import { UpsalesForm } from '@/bloks/Form';
import { renderRichText } from '@/bloks/RichText';
import { PackageContactCardStoryblok, UpsalesFormStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { useState } from 'react';
import styles from './PackageContactCard.module.scss';

interface Props {
	blok: PackageContactCardStoryblok;
	testID?: string;
	meta: {
		zone: number | undefined;
		priceCardV2?: boolean;
	};
}

export const PackageContactCard: React.FC<Props> = ({ blok, meta }) => {
	const form = blok?.form as unknown as ISbStoryData<UpsalesFormStoryblok>;
	const { titleSize, label, title } = blok;
	const numberOfColumns = blok?.formLayout ?? 'oneCol';
	const [showText, setShowText] = useState(true);

	return (
		<div className={cls(meta.priceCardV2 && styles.addSpace)}>
			<Card border={false} className={cls(styles.container, meta.priceCardV2 && styles.cardMaxWidth)}>
				{showText && label && <Heading as="div" size="h6" title={label} />}
				{showText && title && <Heading as="div" size={titleSize ? titleSize : 'h4'} title={title} />}
				{showText && (
					<Text as="div" marginBottom="xl">
						{renderRichText(blok?.text)}
					</Text>
				)}
				{form && (
					<UpsalesForm
						{...UpsalesForm.blokProps({
							story: form,
							meta: {
								...meta,
								layout: numberOfColumns,
								compactFields: true,
								phoneLink: blok.phone,
								afterSubmit: () => setShowText(false),
							},
						})}
					/>
				)}
			</Card>
		</div>
	);
};
