import { IconButton, MenuIcon, Text } from '@/atoms';
import { LinkButton, buildLinkProps } from '@/bloks/Button';
import { SubMenuGroupStoryblok, SubMenuItemStoryblok, SubMenuV2Storyblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import useScrollDirection from '@/hooks/useScrollDirection';
import { cls, removeFirstAndLastSlash } from '@/utils';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import styles from './SubMenuMobile.module.scss';

interface Props {
	items: SubMenuV2Storyblok['items'];
	title: string;
	activeItem?: SubMenuGroupStoryblok | SubMenuItemStoryblok | null;
	className?: string;
}

const getActiveLinkGroup = (items: SubMenuV2Storyblok['items'], pathname: string | null) => {
	return items.find((item) => {
		const itemLinkMatches = removeFirstAndLastSlash(item.link.cached_url) === removeFirstAndLastSlash(pathname);

		const subItemLinkMatches = item.items?.some(
			(subItem) => removeFirstAndLastSlash(subItem.link.cached_url) === removeFirstAndLastSlash(pathname),
		);

		return itemLinkMatches || subItemLinkMatches;
	});
};

export const SubMenuMobile: React.FC<Props> = ({ items, title, activeItem, className }) => {
	const pathname = usePathname();
	const { scrollDirection } = useScrollDirection();
	const [isOpen, setIsOpen] = useState(false);
	const activeLinkGroup = getActiveLinkGroup(items, pathname);
	const [openSubGroupId, setOpenSubGroupId] = useState<string[]>(activeLinkGroup ? [activeLinkGroup._uid] : ['']);
	const { showFirstPanel, isMenuOpen, menuVersionV3 } = useGlobalMenu();
	const subMenuRef = useRef<HTMLElement | null>(null);
	const [expandAndOpen, setExpandAndOpen] = useState(false);
	const [isMinimizable, setIsMinimizable] = useState(false);

	const handleClick = (_uid: string) => {
		if (openSubGroupId.includes(_uid)) {
			setOpenSubGroupId(openSubGroupId.filter((id) => id !== _uid));
		} else {
			setOpenSubGroupId([...openSubGroupId, _uid]);
		}
	};

	useOnClickOutside(subMenuRef, () => {
		setIsOpen(false);
	});

	useEffect(() => {
		setIsOpen(false);
	}, [pathname]);

	useEffect(() => {
		if (scrollDirection === 'down' || isOpen) {
			setIsMinimizable(true);
		}
	}, [scrollDirection, isOpen]);

	const toggleMenu = () => {
		if (scrollDirection === 'up' && !isOpen) {
			setExpandAndOpen(true);
			setIsOpen(true);
			return;
		}

		if (scrollDirection === 'up' && isOpen) {
			setExpandAndOpen(false);
			setIsOpen(false);
			return;
		}

		setIsOpen(!isOpen);
	};

	return (
		<nav
			className={cls(
				styles.subMenuMobile,
				menuVersionV3 && styles.menuVersionV3,
				(showFirstPanel || isMenuOpen) && styles.zIndex,

				/** Animations */
				expandAndOpen && styles.expandAndOpen,
				scrollDirection === 'down' && styles.hideGlobalMenu,
				isOpen && styles.isMenuOpen,
				isMinimizable && !isOpen && scrollDirection === 'up' && styles.minimize,
				isOpen && scrollDirection === 'down' && styles.slideUp,
				scrollDirection === 'up' && styles.slideDown,
				className,
			)}
			ref={subMenuRef}
		>
			<div className={cls(styles.inner, styles.boxShadow)}>
				<div className={styles.navHeader}>
					<MenuIcon color="dark" open={isOpen} onClick={toggleMenu} />
					<Text marginBottom="none" bold className={styles.title}>
						{title}
					</Text>
				</div>

				<div className={cls(styles.links)}>
					{items.map((item) => {
						const hasSubItems = !!item.items?.length;
						const isOpen = openSubGroupId.includes(item._uid);

						return (
							<div key={item._uid} className={styles.linkGroup}>
								<div className={styles.item}>
									<LinkButton
										href={buildLinkProps(item.link).href}
										className={cls(styles.subItem, activeItem?._uid === item._uid && styles.activeLink)}
										variant="link"
									>
										{item.text}
									</LinkButton>
									{hasSubItems && (
										<IconButton
											iconName="chevron-right"
											aria-label={t`Öppna menynivå`}
											iconColor="--text-dark"
											onClick={() => handleClick(item._uid)}
											className={cls(styles.itemIcon, isOpen && styles.rotate)}
										/>
									)}
								</div>

								{hasSubItems && (
									<div className={cls(styles.subItemsGroup, isOpen && styles.isLevelOpen)}>
										{item.items?.map((subItem) => (
											<div className={styles.subItem} key={subItem._uid}>
												<LinkButton
													href={buildLinkProps(subItem.link).href}
													variant="link"
													className={cls(activeItem?._uid === subItem._uid && styles.activeLink)}
												>
													{subItem.text}
												</LinkButton>
											</div>
										))}
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</nav>
	);
};
