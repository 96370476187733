import { BrandLine, Divider, Heading, IconButton, Modal, Section, Tags, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { CardFiveGroup } from '@/bloks/Card/CardFiveGroup';
import SocialMedia from '@/bloks/Header/HeaderArticle/SocialMedia';
import { renderRichText } from '@/bloks/RichText';
import { ArticlePageStoryblok, CardFiveGroupStoryblok } from '@/components';
import { useArticleTags } from '@/contexts/articles/ArticleTagsProvider';
import { useRelatedArticles } from '@/contexts/articles/RelatedArticlesProviderV2';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import useScrollDirection from '@/hooks/useScrollDirection';
import { ImageAsset } from '@/metabloks';
import { cls, useDateString } from '@/utils';
import { normalizeFullSlug } from '@/utils/storyblok';
import { usePathname } from 'next/navigation';
import { ISbStoryData } from 'storyblok-js-client';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from './ArticlePage.module.scss';

export interface ArticlePageProps {
	blok: ArticlePageStoryblok;
	meta: Record<string, any>;
}
interface HeadingElem {
	href: string;
	text: string;
	type: 'h2' | 'h3';
}

export const ArticlePage = ({ blok, meta }: ArticlePageProps) => {
	const { title, image, intro, longText, categoryTag, labelTag, showTableOfContents } = blok;
	const exits = blok.contact as ISbStoryData<CardFiveGroupStoryblok>;
	const { published_at } = meta;
	const [openModal, setOpenModal] = React.useState(false);
	const pathname = usePathname();
	const { articleTags } = useArticleTags();
	const { relatedArticles } = useRelatedArticles();
	const category = articleTags?.categories?.find((category) => category.value === categoryTag)?.name ?? '';
	const label = articleTags?.labels?.find((label) => label.value === labelTag)?.name ?? '';
	const [headings, setHeadings] = useState<HeadingElem[]>([]);
	const ref = React.useRef<HTMLDivElement>(null);
	const { scrollDirection } = useScrollDirection();
	const { hasSubMenuV2 } = useSubMenu();

	useEffect(() => {
		if (!ref.current) return;
		const headingElems = ref.current?.querySelectorAll('h2, h3');

		const tempHeadings: { href: string; text: string; type: 'h2' | 'h3' }[] = [];
		headingElems.forEach((heading: any) => {
			tempHeadings.push({
				href: `#${heading.id}`,
				text: heading.textContent!,
				type: heading.tagName.toLowerCase() as 'h2' | 'h3',
			});
		});

		setHeadings(tempHeadings);
	}, [pathname]);

	const gridLayout = showTableOfContents || relatedArticles.byLabel?.length > 0;

	return (
		<>
			<Section
				className={styles.section}
				maxWidth={gridLayout ? undefined : '1120px'}
				paddingY={{ base: '5xl', xl: '2xl' }}
			>
				<div className={cls(gridLayout && styles.grid)}>
					{gridLayout && (
						<aside>
							<div
								className={cls(styles.sidepanelContent, hasSubMenuV2 && scrollDirection === 'up' && styles.scrollUp)}
							>
								{showTableOfContents && (
									<div className={styles.tableOfContents}>
										<Heading as="h2" size="h5" title={t`Innehåll`} />
										<ul className={styles.list}>
											{headings?.map((heading) => (
												<li key={heading.href} className={styles.listItem}>
													<LinkButton arrow={false} variant="text" href={heading.href}>
														{heading.text}
													</LinkButton>
												</li>
											))}
										</ul>
									</div>
								)}
								{relatedArticles.byLabel.length > 0 && (
									<div>
										<Heading as="h2" size="h5" title={t`Relaterade artiklar`} />
										<ul className={styles.list}>
											{relatedArticles.byLabel.map((article) => (
												<li key={article.uuid} className={styles.listItem}>
													<LinkButton arrow={false} variant="text" href={normalizeFullSlug(article.full_slug)}>
														{article.content.title}
													</LinkButton>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
						</aside>
					)}
					<article className={styles.article}>
						<header>
							<BrandLine className={styles.brandline} />
							<div className={styles.tagContainer}>
								<Tags className={styles.tags} tags={[category, label]} />
								{pathname && <SocialMedia background title={title} />}
							</div>

							<Heading as="h1" size="h2" title={title} marginBottom="xl" />

							<div className={styles.infoContainer}>
								{relatedArticles.byCategory && relatedArticles.byCategory.length > 0 && (
									<div className={styles.buttonContainer} onClick={() => setOpenModal(true)}>
										<IconButton iconName="circle-ellipsis" aria-label={t`Mer om ämnet`} />
										<Text marginBottom="none">{t`Mer om ämnet`}</Text>
									</div>
								)}

								<Text className={styles.published} muted>
									{t`Senast uppdaterad` + ': ' + useDateString(published_at)}
								</Text>
							</div>

							{image?.filename && (
								<div className={styles.image}>
									<ImageAsset absolute priority asset={image} objectFit="cover" fill objectPosition="center" />
								</div>
							)}
						</header>
						<div ref={ref} className={styles.textWrapper}>
							{intro && (
								<Text bold as="div" marginBottom="xl">
									{renderRichText(intro)}
								</Text>
							)}
							{longText && renderRichText(longText, { exo2: true, layout: 'article' })}
						</div>
						<Divider margin="md" />
						<div className={styles.socialMediaFooter}>
							<Text bold marginBottom="none">{t`Dela den här artikeln`}</Text>
							{pathname && <SocialMedia title={title} />}
						</div>
						<Divider margin="md" />
						<Modal
							className={styles.modal}
							show={openModal}
							onClose={() => {
								setOpenModal(false);
							}}
						>
							<Heading as="h2" size="h3" marginBottom="xl" title={t`Mer om ämnet`} />
							<ul className={styles.modalList}>
								{relatedArticles.byCategory.map((article) => (
									<li key={article.uuid} className={styles.listItem}>
										<LinkButton
											href={normalizeFullSlug(article.full_slug)}
											variant="text"
											onClick={() => {
												setOpenModal(false);
											}}
										>
											{article.content.title}
										</LinkButton>
									</li>
								))}
							</ul>
							<LinkButton
								variant="text"
								href={`/byra/magazine/${categoryTag}`}
								onClick={() => {
									setOpenModal(false);
								}}
							>{t`Läs alla artiklar i den här kategorin`}</LinkButton>
						</Modal>
					</article>
					{gridLayout && <aside className={styles.fill} />}
				</div>
			</Section>
			{exits && blok.contact && <CardFiveGroup blok={exits.content} meta={{ ...meta }} />}
		</>
	);
};
