import { Grid, Select, TextField } from '@/atoms';
import { useSearchIntegrations } from '@/contexts/Integration/searchIntegrationsContext';
import AddSearchClient from 'addsearch-js-client';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef } from 'react';
import { t } from 'ttag';
import styles from './SearchIntegrations.module.scss';

export const SearchIntegrations: React.FC = () => {
	const context = useSearchIntegrations();
	const router = useRouter();
	const { search } = router.query;

	const searchFieldRef = useRef<HTMLInputElement>(null);
	const domainFilter = '1xintegrationer';

	const client = useMemo(() => new AddSearchClient('c0925c3c5d699c8506c71c7a9f7b5c3f'), []);

	const DEBOUNCE_TIMEOUT = 300;
	const RESULTS_PER_PAGE = 15;

	useEffect(() => {
		if (searchFieldRef.current) {
			searchFieldRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (search) {
			context.setSearchValue(search as string);
		}
	}, [context, search]);

	const handleSearchQueryChange = (e: React.ChangeEvent<any>) => {
		context.setSearchResult({ hits: [] });
		context.setCurrentPage(1);
		context.setSearchValue(e.target.value);
	};

	const handleCategoryChange = (value: string) => {
		context.setSearchResult({ hits: [] });
		context.setCurrentPage(1);
		context.setCategory({ id: value, value: value, label: value });
	};

	const handleCompanyNameChange = (value: string) => {
		context.setSearchResult({ hits: [] });
		context.setCurrentPage(1);
		context.setCompanyName({ id: value, value: value, label: value });
	};

	useEffect(() => {
		const searchQuery = context.searchValue?.trim() === '' ? '*' : context.searchValue;

		client.setCategoryFilters(domainFilter);
		client.setPaging(context.currentPage, RESULTS_PER_PAGE, context.sortBy, context.sortOrder);

		client.addFacetField('custom_fields.category');
		client.addFacetField('custom_fields.companyName');
		client.setNumberOfFacets(1000);

		const filters = [];

		if (context.selectedCategory.value) {
			filters.push({ 'custom_fields.category': context.selectedCategory.value });
		}

		if (context.selectedCompany.value) {
			filters.push({ 'custom_fields.companyName': context.selectedCompany.value });
		}

		filters.push({ 'custom_fields.pagetype': 'integration' });

		const filterObject = filters.length > 0 ? { and: filters } : undefined;

		client.setFilterObject(filterObject);

		const timer = setTimeout(() => {
			client.search(searchQuery, (res) => {
				const hits = res.hits ?? [];

				const newSearchResult = {
					...res,
					hits: [...context.searchResult.hits, ...hits],
				};

				context.setSearchResult(newSearchResult);
				context.setHasMoreHits(res?.total_hits > newSearchResult.hits.length);

				if (res.facets['custom_fields.category']) {
					const categorySelectOptions = [
						...res.facets['custom_fields.category'].map((facet: any) => ({
							id: facet.value,
							label: `${facet.value} (${facet.count})`,
							value: facet.value,
						})),
					];
					context.setCategoryOptions(categorySelectOptions);
				}

				if (res.facets['custom_fields.companyName']) {
					const companySelectionOptions = [
						...res.facets['custom_fields.companyName'].map((facet: any) => ({
							id: facet.value,
							label: facet.value,
							value: facet.value,
						})),
					];
					context.setCompanyNameOptions(companySelectionOptions);
				}
			});
		}, DEBOUNCE_TIMEOUT);

		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		context.searchValue,
		client,
		context.currentPage,
		context.sortBy,
		context.selectedCategory,
		context.selectedCompany,
		context.sortOrder,
	]);

	return (
		<div className={styles.container}>
			<Grid className={styles.inputContainer} columns={{ base: 1, lg: 3 }} colGap="xl" rowGap="xl">
				<Grid.Item>
					<Select
						searchable
						onSelect={(value) => {
							value === 'showAllCategories' ? router.push('/integrationer/kategorier') : handleCategoryChange(value);
							window.dataLayer.push({
								event: 'dropdownFilter',
								dropdownFilterType: 'Integration Category',
								dropdownFilterOption: value,
							});
						}}
						testID="category"
						title={t`Välj kategori`}
						aria-label={t`Välj kategori`}
						data={context.categoryOptions}
						selected={context.selectedCategory}
						onClear={() => handleCategoryChange('')}
						id="integration-categories"
					/>
				</Grid.Item>
				<Grid.Item>
					<Select
						searchable
						onSelect={(value) => {
							value === 'showAllCompanies'
								? router.push('/integrationer/leverantorer')
								: handleCompanyNameChange(value);
							window.dataLayer.push({
								event: 'dropdownFilter',
								dropdownFilterType: 'Integration Supplier',
								dropdownFilterOption: value,
							});
						}}
						testID="leverantor"
						title={t`Välj leverantör`}
						aria-label={t`Välj leverantör`}
						data={context.companyNameOptions}
						defaultValue={context.selectedCompany}
						selected={context.selectedCompany}
						onClear={() => handleCompanyNameChange('')}
						id="integration-companies"
					/>
				</Grid.Item>
				<Grid.Item>
					<TextField
						type="search"
						bordered
						title={t`Sök integrationer`}
						onChange={handleSearchQueryChange}
						value={context.searchValue}
					/>
				</Grid.Item>
			</Grid>
		</div>
	);
};
