import { Checkbox } from '@/atoms';
import { capitalizeEachWord } from '@/utils';
import React from 'react';
import styles from './MultiChoiceFilter.module.scss';

export interface Choice {
	key: string;
	whitelist: string[];
	blacklist: string[];
}

interface Props {
	choices: Choice[];
	selectedChoices: Choice[];
	setSelectedChoices: (values: Choice[]) => void;
	title: string;
	text: string;
}

export const filterMatch = (selectedFilterChoices: Choice[], text: string) => {
	return selectedFilterChoices.every((choice) => {
		let reducedText = text.toLowerCase();
		choice.blacklist.forEach((word) => {
			reducedText = reducedText.replaceAll(word, '');
		});

		return choice.whitelist.find((value) => reducedText.includes(value));
	});
};

export const MultiChoiceFilter: React.FC<Props> = ({ choices, selectedChoices, setSelectedChoices, title, text }) => {
	const isChecked = (searchFilter: Choice[], choice: Choice) => {
		return searchFilter.filter((filter) => filter.key === choice.key).length > 0;
	};
	return (
		<div className={styles.checkboxContainer}>
			<div className={styles.checkboxGrid}>
				{choices.sort().map((choice) => {
					return (
						<Checkbox
							key={choice.key}
							bordered={true}
							checked={isChecked(selectedChoices, choice)}
							className={styles.checkbox}
							label={capitalizeEachWord(choice.key)}
							onClick={() => {
								const strings = selectedChoices.filter((selected) => selected.key !== choice.key);
								if (!isChecked(selectedChoices, choice)) {
									strings.push(choice);
								}
								setSelectedChoices(strings);
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};
