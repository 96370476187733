import { AnimationProvider } from '@/contexts/Animations/AnimationProvider';
import { LatestPressReleaseProvider, NewsfeedProvider } from '@/contexts/News';
import { ArticleTagsProvider } from '@/contexts/articles/ArticleTagsProvider';
import { RelatedArticlesProviderV2 } from '@/contexts/articles/RelatedArticlesProviderV2';
import { StoryblokBridgeProvider } from '@/contexts/bridge';
import { ProductsProvider } from '@/contexts/products';
import { SectionComparisonProvider } from '@/contexts/products/SectionComparisonProvider';
import { TeamtailorProvider } from '@/contexts/teamtailor/TeamtailorProvider';
import { PageDataProviderParams } from '@/server/storyblok/enabledModules/types';
import { ReactNode } from 'react';

interface ClientProvidersProps {
	children: ReactNode;
	providerParams: PageDataProviderParams;
}

export const ClientProviders: React.FC<ClientProvidersProps> = ({ children, providerParams }) => {
	const {
		productCatalog,
		newsfeed,
		latestPressRelease,
		teamtailorData,
		segmentDescriptions,
		animation,
		articleTags,
		relatedArticlesV2,
	} = providerParams;

	const providers = [
		{ Provider: StoryblokBridgeProvider, required: true },
		{ Provider: ProductsProvider, optional: { value: productCatalog } },
		{ Provider: NewsfeedProvider, optional: { value: newsfeed } },
		{ Provider: LatestPressReleaseProvider, optional: { value: latestPressRelease } },
		{ Provider: TeamtailorProvider, optional: { value: teamtailorData } },
		{ Provider: SectionComparisonProvider, optional: { value: segmentDescriptions } },
		{ Provider: AnimationProvider, optional: { value: animation } },
		{ Provider: ArticleTagsProvider, optional: { value: articleTags } },
		{ Provider: RelatedArticlesProviderV2, optional: { value: relatedArticlesV2 } },
	];
	const providerTree = providers.reduce((acc, provider) => {
		const { Provider, optional, required } = provider;
		const params = Object.fromEntries(Object.entries(optional || {}).filter(([, value]) => value !== undefined)) as any;
		if (!required && !Object.keys(params).length) return acc;
		return <Provider {...params}>{acc}</Provider>;
	}, children);
	return <>{providerTree}</>;
};
