import { Heading } from '@/atoms';
import { SubMenuGroupStoryblok, SubMenuItemStoryblok, SubMenuV2Storyblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { removeFirstAndLastSlash } from '@/utils';
import { usePathname } from 'next/navigation';
import { ISbStoryData } from 'storyblok-js-client';
import styles from './SubMenuV2.module.scss';
import { SubMenuDesktop } from './components/SubMenuDesktop/SubMenuDesktop';
import { SubMenuMobile } from './components/SubMenuMobile/SubMenuMobile';

interface Props {
	subMenu: ISbStoryData<SubMenuV2Storyblok>;
}

const findActiveItem = (
	items: SubMenuV2Storyblok['items'],
	pathname: string | null,
): SubMenuGroupStoryblok | SubMenuItemStoryblok | null => {
	for (const item of items) {
		if (removeFirstAndLastSlash(item.link.cached_url) === removeFirstAndLastSlash(pathname)) {
			return item;
		}

		if (item.items) {
			const subItem = item.items.find(
				(subItem) => removeFirstAndLastSlash(subItem.link.cached_url) === removeFirstAndLastSlash(pathname),
			);
			if (subItem) {
				return subItem;
			}
		}
	}
	return null;
};

export const SubMenuV2: React.FC<Props> = ({ subMenu }) => {
	const pathname = usePathname();
	const subMenuContent = subMenu.content;
	const title = subMenuContent.title;
	const items = subMenuContent.items;
	const activeItem = findActiveItem(items, pathname);

	return (
		<ThemeProvider theme="submenuV2">
			<div className={styles.titleWrapper}>
				<Heading as="div" size="h5" title={title} marginBottom="none" />
			</div>
			<SubMenuDesktop items={items} activeItem={activeItem} className={styles.subMenuDesktop} />
			<SubMenuMobile items={items} title={title} activeItem={activeItem} className={styles.subMenuMobile} />
		</ThemeProvider>
	);
};
