import { Icon, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { ProductUspStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import styles from './ProductUsp.module.scss';

interface BlokProps {
	blok: ProductUspStoryblok;
}

const blokProps = ({ blok }: BlokProps): ProductUspProps => ({
	text: blok.text,
	_uid: blok._uid,
	_editable: blok._editable,
});

export interface ProductUspProps {
	text: string;
	anti?: boolean;
	_uid: string;
	_editable?: string;
}

export const ProductUsp: Blok<ProductUspProps, BlokProps> = ({ text, anti, _editable }) => {
	return (
		<li {...editableBlok({ _editable })} className={cls(styles.usp, anti && styles.muted)}>
			<Icon name={anti ? 'circle-xmark' : 'circle-check'} className={styles.icon} usp={!anti} antiUsp={anti} />
			<Text marginBottom="none">{text}</Text>
		</li>
	);
};

ProductUsp.blokProps = blokProps;
