import Image from 'next/image';
import { CSSProperties } from 'react';
import styles from './Logo.module.scss';

const SIZES = {
	'162.5x35.5': { width: 162.5, height: 35.5 },
	'138x30.14': { width: 138, height: 30.14 },
	'145x30': { width: 145, height: 30 },
	'96x20': { width: 96, height: 20 },
} as const;

type Props = { size: keyof typeof SIZES; mobileSize?: keyof typeof SIZES };

export const Logo: React.FC<Props> = ({ size, mobileSize }) => {
	const { width, height } = SIZES[size];
	const { width: mbWidth, height: mbHeight } = SIZES[mobileSize || size];

	const style = {
		'--desktop-logo-width': `${width}px`,
		'--desktop-logo-height': `${height}px`,
		'--mobile-logo-width': `${mbWidth}px`,
		'--mobile-logo-height': `${mbHeight}px`,
	} as CSSProperties;

	return (
		<Image
			src="/fortnox-text-logo-white-svg.svg"
			alt="Fortnox logotyp"
			priority
			loading="eager"
			style={style}
			className={styles.logo}
			width={width}
			height={height}
		/>
	);
};
