interface piwikBaseEvent {
	zone?: number | undefined | 'popup' | 'sidebar';
	event?: string;
}

interface piwikDimensionEvent {
	customDimensionId: number;
	customDimensionValue: string;
}
interface piwikPriceCardEvent extends piwikBaseEvent {
	productId?: string | number | string[] | number[];
	productName?: string | string[];
	buttonVariant?: 'filled' | 'outlined' | 'text';
	buttonText?: string;
}

interface piwikCustomEvent extends piwikBaseEvent {
	event_category?: string | number;
	event_action?: string | number;
	event_label?: string | number;
}

interface piwikCustomerTypeEvent {
	event_category: string;
}

export type formTypes = 'upsales' | 'zendesk' | 'kp' | 'cision' | 'eduadmin' | 'calculator';

interface piwikFormEvent extends piwikBaseEvent {
	type?: formTypes;
	formId?: string;
}

/*
 * Send information about form submit event to piwik
 * @deprecated
 **/
export const handleFormSubmitEvent = ({ type, zone, formId }: piwikFormEvent) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'formSubmission',
		type,
		zone,
		formId,
	});
};

/*
 * Send information about a calculator event to piwik
 **/
export const handleCalculatorSubmitEvent = (eventName: string) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'calculatorSubmit',
		eventCategory: 'Calculator',
		eventAction: 'Submit',
		eventName,
	});
};

/*
 * Send information about form submit event to piwik
 **/
export const handleFormSubmitEventNew = (eventName: string) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'formSubmission',
		eventCategory: 'Form',
		eventAction: 'Submit',
		eventName,
	});
};

/*
 * Send information about form submit event to piwik
 **/
export const handleFormStartEvent = (eventName: string) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'formSubmission',
		eventCategory: 'Form',
		eventAction: 'Start',
		eventName,
	});
};

/*
 * Send information about price card click event to piwik
 **/
export const handlePriceCardClickEvent = ({
	productId,
	productName,
	zone,
	buttonVariant,
	buttonText,
}: piwikPriceCardEvent) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'priceCardClick',
		productId,
		productName,
		zone,
		buttonVariant,
		buttonText,
	});
};

// In the end if we see the zone is not used at all then we make undefined as default for zone.
export const handleCustomEvent = ({ event_category, event_label, event_action, zone }: piwikCustomEvent) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'customEvent',
		event_action,
		event_category,
		event_label,
		zone,
	});
};
/*
 * Send information about tab menu click event to piwik
 **/
export const handleTabClickEvent = ({ zone, buttonText }: piwikPriceCardEvent) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'tabMenuClick',
		zone,
		buttonText,
	});
};

export const sendCustomerType = ({ event_category }: piwikCustomerTypeEvent) => {
	window.dataLayer ??= [];
	window.dataLayer.push({
		event: 'fortnoxCustomerType',
		event_category,
	});
};

export const handleCustomDimension = ({ customDimensionId, customDimensionValue }: piwikDimensionEvent) => {
	if (!global.window?._paq) {
		return;
	}
	window._paq.push(['setCustomDimensionValue', customDimensionId, customDimensionValue]);
};

declare global {
	interface Window {
		dataLayer: {
			push<T extends piwikBaseEvent>(event: T): void;
		};
		_paq: {
			push: (event: any) => void;
		};
	}
}
