import { TableBlokStoryblok, ThemeV2Storyblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { ISbComponentType } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { Blok } from '../../Blok';
import styles from './TableBlok.module.scss';

interface BlokTableHead extends ISbComponentType<'_table_head'> {
	value: string;
}

interface BlokTableCol extends ISbComponentType<'_table_col'> {
	value: string;
}

interface BlokTableRow extends ISbComponentType<'_table_row'> {
	value: any;
	body: BlokTableCol[] | undefined;
}

export interface BlokTable {
	table: { fieldtype: 'table'; thead: BlokTableHead[]; tbody: BlokTableRow[] | TableRow[] };
}

interface BlokProps {
	blok: BlokTable | TableBlokStoryblok;
	meta?: {
		leftTdIsLabel?: boolean;
		showBorderRight?: boolean;
		/**
		 * If table cells contains code blocks it will be wrapped in a <code> tag
		 */
		containsCode?: boolean;
	};
	className?: string;
	_editable?: string;
}

export const blokProps = ({ blok, meta }: BlokProps): Props => {
	const tableHead: TableHead[] | undefined = blok?.table?.thead?.map((th) => {
		return { value: th.value };
	});

	const tableRows: TableRow[] | undefined = blok?.table?.tbody?.map((tr) => {
		const body: TableCol[] | undefined = tr?.body?.map((b) => {
			return { value: b.value } as TableCol;
		});
		return { body };
	});

	const table: TableElem = {
		value: { fieldtype: 'table', thead: tableHead, tbody: tableRows },
	} as unknown as TableElem;

	const props: Props = {
		table,
	};

	if (meta) {
		const { leftTdIsLabel, showBorderRight, containsCode } = meta;
		Object.assign(props, { leftTdIsLabel, showBorderRight, containsCode });
	}

	return props;
};

export interface TableElem {
	value: { fieldtype: 'table'; thead?: TableHead[]; tbody?: TableRow[] };
}

export interface TableHead {
	value?: React.ReactNode;
}

export interface TableCol {
	value?: React.ReactNode;
}

export interface TableRow {
	body?: TableCol[] | undefined;
	className?: string;
}

interface Props {
	table: TableElem;
	theme?: ThemeV2Storyblok['theme'];
	leftTdIsLabel?: boolean;
	showBorderRight?: boolean;
	/**
	 * If table cells contains code blocks it will be wrapped in a <code> tag
	 */
	containsCode?: boolean;
	layout?: 'article';
	className?: string;
}

export const TableBlok: Blok<Props, BlokProps> = ({
	table,
	theme = 'lightBeige',
	leftTdIsLabel = false,
	showBorderRight = true,
	containsCode = false,
	className,
}) => {
	const themeInfo = getThemeInfo(theme);
	const thead = table?.value?.thead;
	const tbody = table?.value?.tbody;

	if (!tbody) return null;

	const headerLabels = thead?.map((th) => th.value);

	return (
		<ThemeProvider theme={themeInfo.name}>
			<div className={styles.container}>
				<table
					{...editableBlok({ component: 'table', ...table })}
					className={cls(
						styles.table,
						themeInfo.name === 'white' && styles.outerBorder,
						leftTdIsLabel && styles.leftTdIsLabel,
						showBorderRight && styles.showBorderRight,
						className && styles[className],
						themeInfo.styles.borderColor,
					)}
				>
					<thead>
						<tr>
							{thead?.map((th, i) => (
								<th key={i} className={styles.tableHead}>
									{th.value}
								</th>
							))}
						</tr>
					</thead>
					<tbody className={cls(styles.tableBody, themeInfo.styles.textColor)}>
						{tbody?.map((tr, i) => (
							<tr key={i} className={cls(tr?.className, themeInfo.styles.bgColor)}>
								{tr.body?.map((td, index) => (
									<td
										key={index}
										className={cls(styles.tableData, td.value ? styles.td : styles.emptyTd)}
										data-label={`${headerLabels?.[index]}`}
									>
										{containsCode ? <code className={themeInfo.styles.textColor}>{td.value}</code> : td.value}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</ThemeProvider>
	);
};

TableBlok.blokProps = blokProps;
