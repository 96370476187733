import { BrandLine, ContainerFluid, Heading, Icon, IconButton, LinkAnchor, MenuIcon } from '@/atoms';
import { LinkButton, buildLinkProps } from '@/bloks/Button/LinkButton';
import {
	SubMenuItemFirstLevelGroupStoryblok,
	SubMenuItemSecondLevelGroupStoryblok,
	SubMenuItemThirdLevelStoryblok,
	SubMenuStoryblok,
} from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { getThemeInfo } from '@/themes';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { IconName } from 'icons/types';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import styles from './SubMenu.module.scss';

interface Props {
	story: ISbStoryData<SubMenuStoryblok>;
	className?: string;
	meta: Record<any, any>;
}

type Level = 1 | 2 | 3;

const getInitialLevel = (menuContent: SubMenuStoryblok['content'], slug: string): Level => {
	for (const item1 of menuContent) {
		for (const item2 of item1.items || []) {
			for (const item3 of item2.items || []) {
				if (item3.link.cached_url === slug) return 3;
			}
			if (item2.link.cached_url === slug) return 2;
		}
		if (item1.link.cached_url === slug) return 1;
	}
	return 1;
};

export function SubMenu({ story, className, meta }: Props) {
	const { full_slug } = meta;
	const themeInfo = getThemeInfo('submenu');
	const blok = story?.content;
	const { heading, content } = blok;
	const { showFirstPanel, isMenuOpen, menuVersionV3 } = useGlobalMenu();
	const [level, setLevel] = useState<Level>(getInitialLevel(content, full_slug));
	const activeLinkGroup = content.findIndex(
		(item) =>
			item.link.cached_url === full_slug ||
			item.items?.some(
				(item) => item.link.cached_url === full_slug || item.items?.some((item) => item.link.cached_url === full_slug),
			),
	);
	const initialSecondLevelIndex = content[activeLinkGroup]?.items?.findIndex(
		(item) => item.link.cached_url === full_slug || item.items?.some((item) => item.link.cached_url === full_slug),
	);

	const [firstLevelIndex, setFirstLevelIndex] = useState<null | number>(activeLinkGroup);
	const [secondLevelIndex, setSecondLevelIndex] = useState<null | number>(initialSecondLevelIndex ?? null);
	const [isOpen, setIsOpen] = useState(false);
	const ticking = useRef(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [hideGlobalMenu, setHideGlobalMenu] = useState(false);
	const [expandAndOpen, setExpandAndOpen] = useState(false);
	const [isMinimizable, setIsMinimizable] = useState(false);

	const openGroupLevelOne = (index: number) => {
		setFirstLevelIndex(index);
		setLevel(level !== 1 && index === firstLevelIndex ? 1 : 2);
	};

	const openGroupLevelTwo = (index: number) => {
		setSecondLevelIndex(index);
		setLevel(level !== 2 && index === secondLevelIndex ? 2 : 3);
	};

	useEffect(() => {
		setIsOpen(false);
	}, [full_slug]);

	useEffect(() => {
		if (typeof window != 'undefined' && window.document) {
			document.body.style.overflow = isOpen === true ? 'hidden' : 'unset';
		}
	}, [isOpen]);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;

			if (!ticking.current) {
				window.requestAnimationFrame(() => {
					const topOffset = document.body.scrollTop > 50 || document.documentElement.scrollTop > 50; // Distance from top
					const scrollOffset = Math.abs(currentScrollPos - prevScrollPos) > 30; // Scrolling distance

					if (topOffset && scrollOffset) {
						const isVisible = prevScrollPos < currentScrollPos;

						setHideGlobalMenu(isVisible);
						setPrevScrollPos(currentScrollPos);
					}
					ticking.current = false;
				});

				ticking.current = true;
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [prevScrollPos]);

	useEffect(() => {
		if (hideGlobalMenu || isOpen) {
			setIsMinimizable(true);
		}
	}, [hideGlobalMenu, isOpen]);

	const toggleMenu = () => {
		if (!hideGlobalMenu && !isOpen) {
			setExpandAndOpen(true);
			setIsOpen(true);
			return;
		}

		if (!hideGlobalMenu && isOpen) {
			setExpandAndOpen(false);
			setIsOpen(false);
			return;
		}

		setIsOpen(!isOpen);
	};

	return (
		<ContainerFluid theme="submenu">
			<nav
				className={cls(
					styles.container,
					themeInfo.styles.bgColor,
					(showFirstPanel || isMenuOpen) && styles.zIndex,
					!menuVersionV3 && styles.oldMenu, // Remove when all menus are updated

					/** Animations */
					expandAndOpen && styles.expandAndOpen,
					hideGlobalMenu && styles.hideGlobalMenu,
					isOpen && styles.isMenuOpen,
					isMinimizable && !isOpen && !hideGlobalMenu && styles.minimize,
					isOpen && hideGlobalMenu && styles.slideUp,
					!hideGlobalMenu && styles.slideDown,
					className,
				)}
			>
				<div className={styles.header}>
					<BrandLine className={styles.brandline} />

					<MenuIcon color="dark" open={isOpen} onClick={toggleMenu} className={styles.hamburgerIcon} />

					<Heading as="div" size="h6" title={heading} marginBottom="none" className={styles.title} />
				</div>

				<div className={styles.links}>
					<ul className={cls(styles.linkGroup, styles.firstLevel, isOpen && styles.isOpen)}>
						{content.map((firstLevelLinkGroup, index) => (
							<li
								key={firstLevelLinkGroup._uid}
								className={cls(styles.linkItem, {
									[styles.activeGroup]: activeLinkGroup === index,
								})}
							>
								{firstLevelLinkGroup?.items?.length ? (
									<MenuGroupItem
										linkGroup={firstLevelLinkGroup}
										active={full_slug === firstLevelLinkGroup.link.cached_url}
										onClick={() => openGroupLevelOne(index)}
										iconName={firstLevelIndex === index && level !== 1 ? 'chevron-down' : 'chevron-right'}
									/>
								) : (
									<MenuItem item={firstLevelLinkGroup} active={full_slug === firstLevelLinkGroup.link.cached_url} />
								)}

								{!!firstLevelLinkGroup?.items?.length && (
									<ul
										className={cls(
											styles.secondLevel,
											styles.linkGroup,
											styles.animation,
											firstLevelIndex === index && level !== 1 && styles.open,
										)}
									>
										{firstLevelLinkGroup.items?.map((secondLevelLinkGroup, index) => (
											<li key={secondLevelLinkGroup._uid}>
												{secondLevelLinkGroup?.items?.length ? (
													<MenuGroupItem
														linkGroup={secondLevelLinkGroup}
														active={full_slug === secondLevelLinkGroup.link.cached_url}
														onClick={() => openGroupLevelTwo(index)}
														iconName={secondLevelIndex === index && level === 3 ? 'chevron-down' : 'chevron-right'}
													/>
												) : (
													<MenuItem
														item={secondLevelLinkGroup}
														active={full_slug === secondLevelLinkGroup.link.cached_url}
													/>
												)}

												{!!secondLevelLinkGroup?.items?.length && (
													<ul
														className={cls(
															styles.thirdLevel,
															styles.linkGroup,
															styles.animation,
															secondLevelIndex === index && level === 3 && styles.open,
														)}
													>
														{secondLevelLinkGroup.items?.map((thirdLevelLinkItems) => (
															<li key={thirdLevelLinkItems._uid}>
																<MenuItem
																	item={thirdLevelLinkItems}
																	active={full_slug === thirdLevelLinkItems.link.cached_url}
																/>
															</li>
														))}
													</ul>
												)}
											</li>
										))}
									</ul>
								)}
							</li>
						))}
					</ul>
				</div>
			</nav>
		</ContainerFluid>
	);
}

interface MenuGroupItemProps {
	linkGroup: SubMenuItemFirstLevelGroupStoryblok | SubMenuItemSecondLevelGroupStoryblok;
	iconName: IconName;
	onClick?: () => void;
	active?: boolean;
}

const MenuGroupItem: React.FC<MenuGroupItemProps> = ({ linkGroup, iconName, onClick, active }) => {
	return (
		<div className={styles.linkWrapper}>
			<LinkAnchor {...buildLinkProps(linkGroup.link!)} className={cls(styles.link, { [styles.activeLink]: active })}>
				{linkGroup.title}
			</LinkAnchor>

			<IconButton
				aria-label={t`Öppna menynivå`}
				iconName={iconName}
				className={styles.icon}
				iconSize="1x"
				onClick={onClick}
			/>
		</div>
	);
};

interface MenuItemProps {
	item: SubMenuItemFirstLevelGroupStoryblok | SubMenuItemSecondLevelGroupStoryblok | SubMenuItemThirdLevelStoryblok;
	active?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, active }) => {
	const external = item.link?.target === '_blank';

	return external ? (
		<div className={styles.linkWrapper}>
			<LinkButton
				className={cls(styles.link, styles.external)}
				key={item.link.id}
				variant="text"
				href={item.link.cached_url ?? ''}
				arrow={false}
				target="_blank"
			>
				{item.title}
			</LinkButton>
			<Icon className={cls(styles.icon, styles.externalIcon)} name="external-link" color="--action-color" size="xs" />
		</div>
	) : (
		<LinkAnchor
			{...buildLinkProps(item.link!)}
			className={cls(styles.link, {
				[styles.activeLink]: active && !item.link.anchor,
			})}
		>
			{item.title}
		</LinkAnchor>
	);
};
