import { ArticleGroup } from '@/bloks';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SubMenu } from '@/bloks/Menu';
import { CardFiveGroupStoryblok, SubMenuStoryblok, SubMenuV2Storyblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ArticleCardProps } from '@/types/article';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import React, { ReactNode, useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from './PageLayout.module.scss';

interface Props {
	children: ReactNode;
	subMenu?: ISbStoryData<SubMenuStoryblok | SubMenuV2Storyblok>;
	relatedArticles?: ArticleCardProps[];
	exits?: ISbStoryData<CardFiveGroupStoryblok>;
	meta: Record<any, any>;
}

export const PageLayout: React.FC<Props> = ({ children, subMenu, relatedArticles, exits, meta }) => {
	const { hasSubMenu, hasSubMenuV1, hasSubMenuV2 } = useSubMenu();
	const { menuVersionV3 } = useGlobalMenu();
	const [onScroll, setOnScroll] = useState(false);

	useEffect(() => {
		window.onscroll = function () {
			scrollFunction();
		};
		return () => {
			window.onscroll = null;
		};
	}, []);

	const scrollFunction = () => {
		if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
			setOnScroll(true);
		} else {
			setOnScroll(false);
		}
	};

	return (
		<div className={styles.container}>
			{hasSubMenuV1 && subMenu && typeof subMenu !== 'string' && (
				<SubMenu story={subMenu as ISbStoryData<SubMenuStoryblok>} meta={meta} className={styles.mobileMenu} />
			)}

			<div
				className={cls(
					styles.innerContainer,
					hasSubMenu && styles.hasSubMenu,
					hasSubMenuV1 && styles.hasSubMenuV1,
					hasSubMenuV2 && styles.hasSubMenuV2,
					menuVersionV3 && styles.menuVersionV3,
				)}
			>
				{hasSubMenuV1 && (
					<div className={styles.subMenuColumn}>
						{subMenu && typeof subMenu !== 'string' && (
							<aside className={cls(styles.subMenu, onScroll && styles.onScroll)}>
								<SubMenu story={subMenu as ISbStoryData<SubMenuStoryblok>} meta={meta} />
							</aside>
						)}
					</div>
				)}

				<div className={styles.contentColumn}>{children}</div>
			</div>

			{!!relatedArticles?.length && <ArticleGroup title={t`Relaterade artiklar`} articles={relatedArticles} />}

			{exits && <DynamicComponent blok={exits.content} meta={{ ...meta, zone: 5 }} />}
		</div>
	);
};
