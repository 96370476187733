import { ContainerFluid, Divider, Grid, Section } from '@/atoms';
import { CardKeyFiguresEquationGroup, CardKeyFiguresGroup, CardLargeLinksHigh } from '@/bloks/Card';
import { DateText, VideoGroup } from '@/bloks/Other';
import {
	CardKeyFiguresEquationGroupStoryblok,
	CardKeyFiguresGroupStoryblok,
	SectionFinancialKeyFiguresStoryblok,
} from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionFinancialKeyFigures.module.scss';

interface Props {
	blok: SectionFinancialKeyFiguresStoryblok;
	meta?: Record<string, any>;
}

export function SectionFinancialKeyFigures({ blok, meta }: Props) {
	const { header, video, footer } = blok;
	const figures = blok?.figures as unknown as ISbStoryData<CardKeyFiguresGroupStoryblok>;
	const { hasSubMenuV1 } = useSubMenu();
	const figuresWithEquation =
		blok?.figuresWithEquation as unknown as ISbStoryData<CardKeyFiguresEquationGroupStoryblok>;

	return (
		<ContainerFluid theme="darkGreen">
			<Section paddingX={hasSubMenuV1 ? { base: 'md', lg: '3xl', xl: 'none' } : undefined} {...editableBlok(blok)}>
				<div className={styles.container}>
					{header?.[0] && (
						<>
							<CardLargeLinksHigh
								{...CardLargeLinksHigh.blokProps({
									className: styles.header,
									blok: header[0],
									meta: { noPadding: true },
								})}
							/>
							<Divider />
						</>
					)}

					{figures && (
						<>
							<Grid
								className={styles.section}
								columns={figuresWithEquation ? { base: 1, lg: '2fr 3fr', xl: '1fr 2fr' } : { base: 1 }}
							>
								{figures?.content && (
									<Grid.Item>
										<CardKeyFiguresGroup blok={figures.content} />
									</Grid.Item>
								)}
								{figuresWithEquation?.content && (
									<Grid.Item>
										<CardKeyFiguresEquationGroup blok={figuresWithEquation.content} />
									</Grid.Item>
								)}
							</Grid>
							<Divider />
						</>
					)}

					{video?.map((vid) => (
						<>
							<VideoGroup className={styles.section} blok={vid} meta={{ ...meta }} />
							<Divider />
						</>
					))}

					{footer?.[0] && <DateText {...DateText.blokProps({ className: styles.footer, blok: footer[0] })} />}
				</div>
			</Section>
		</ContainerFluid>
	);
}
