import { Icon, Tooltip } from '@/atoms';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import { Validator } from '@/validation';
import { CSSProperties, ChangeEvent, FocusEvent, useEffect, useId, useState } from 'react';
import { t } from 'ttag';
import { useFieldId, useFormValidation } from '../CaptchaForm/FormValidationContext';
import { FieldLabel } from './FieldLabel';
import styles from './TextField.module.scss';

type InputMode = 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;

const getInputMode = (type: string): InputMode => {
	if (type === 'search') {
		return 'search';
	}

	if (type === 'number') {
		return 'numeric';
	}

	if (type === 'tel') {
		return 'tel';
	}

	if (type === 'email') {
		return 'email';
	}
};

interface Props {
	/**
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
	 */
	autoComplete?:
		| 'email' /** email */
		| 'family-name' /** first name */
		| 'given-name' /** last name */
		| 'postal-code' /** zip code */
		| 'street-address' /** most commonly this seem to use use city */
		| 'tel-national' /** phone */
		| 'organization' /** company / organization */
		| 'address-line1' /** for example Streetway 1 */
		| 'address-level2' /** the the city */
		| 'off';

	type: 'text' | 'search' | 'email' | 'tel' | 'textarea' | 'number' | 'hidden';
	placeholder?: string;
	size?: number;
	title?: string;
	label?: string;
	id?: string;
	onChange?: (e: React.ChangeEvent<any>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	maxLength?: number;
	onFocus?: (e: React.FocusEvent<any>) => void;
	className?: string;
	value?: string | number;
	bordered?: boolean;
	required?: boolean;
	requiredMessage?: string;
	defaultValue?: string | number;
	validators?: Validator | null;
	testID?: string;
	name?: string;
	pattern?: string;
	readOnly?: boolean;
	disabled?: boolean;
	hidden?: boolean;
	tooltip?: string;
	compact?: boolean;
	/**
	 * Specifies height in pixels if type is set to textarea
	 */
	height?: string | number;
	borderRadius?: '--border-radius-md' | '--border-radius-5xl';
	forrwardedRef?: any;
}

/**
 * TextField component
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=398%3A10641
 */
export const TextField: React.FC<Props> = ({
	type,
	placeholder,
	size,
	title,
	label,
	onChange,
	onKeyDown,
	maxLength,
	onFocus,
	className,
	value: controlledValue,
	bordered,
	required = false,
	requiredMessage,
	defaultValue,
	validators,
	height = 120,
	testID,
	name,
	pattern,
	readOnly = false,
	disabled = false,
	hidden = false,
	tooltip,
	compact = false,
	borderRadius = '--border-radius-md',
	forrwardedRef,
	...rest
}) => {
	let inputId = useId();
	if (rest.id) inputId = rest.id;

	const [validationMessage, setValidationMessage] = useState('');
	const { setValidations, validations } = useFormValidation();
	const { fieldId } = useFieldId();
	const [isFocused, setIsFocused] = useState(false);
	const [isEmpty, setIsEmpty] = useState(true);
	const [localValue, setLocalValue] = useState<string | undefined>();
	const controlled = typeof controlledValue !== 'undefined';
	const value = controlled ? controlledValue : localValue;
	const inputMode = getInputMode(type);

	const sharedProps = {
		...rest,
		placeholder,
		size,
		title,
		label,
		id: inputId,
		onChange,
		maxLength,
		value,
		name,
		required,
		defaultValue,
		pattern,
		readOnly,
		disabled,
		hidden,
		tooltip,
		type,
		inputMode,
	};

	useEffect(() => {
		value && value !== '' ? setIsEmpty(false) : setIsEmpty(true);
	}, [value]);

	const validate = (e: any) => {
		const validationMessage = requiredMessage ?? t`Fältet är obligatoriskt`;
		if (required && e.target.value === '') {
			setValidationMessage(validationMessage);
			updateValidation(validationMessage);
		} else if (e.target.value && validators) {
			const validation = validators(e.target.value);
			if (validation === true) {
				setValidationMessage('');
				updateValidation('');
			} else {
				setValidationMessage(validation);
				updateValidation(validation);
			}
		} else {
			setValidationMessage('');
			updateValidation('');
		}
	};

	const updateValidation = (validationMessage: string) => {
		if (fieldId) {
			const validationErrorClone = new Map(validations);
			validationErrorClone?.set(fieldId, validationMessage);
			setValidations(validationErrorClone);
		}
	};

	const handleOnFocus = (e: FocusEvent<any>) => {
		setIsFocused(true);
		if (onFocus) {
			onFocus(e);
		}
		setValidationMessage('');
	};

	const handleOnBlur = (e: ChangeEvent<any>) => {
		if (!controlled) setLocalValue(e.currentTarget.value);
		validate(e);
		setIsFocused(false);
	};

	const handleOnChange = (e: ChangeEvent<any>) => {
		if (!controlled) setLocalValue(e.currentTarget.value);
		if (document.activeElement !== e.currentTarget) {
			validate(e);
		}
		return sharedProps.onChange?.(e);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		onKeyDown?.(e);
	};

	const textarea = type === 'textarea';

	const textFieldStyle = { '--border-radius': `var(${borderRadius})` } as CSSProperties;

	const textAreaStyle = {
		...textFieldStyle,
		...(height ? { height: `${height}px` } : {}),
	};

	const themeInfo = useTheme();

	return (
		<div>
			<div
				data-testid="input-textfield-container"
				className={cls(
					styles.container,
					bordered && styles.bordered,
					validationMessage && !disabled && styles.validationError,
					isFocused && styles.active,
					hidden && styles.hidden,
					type === 'search' && styles.searchField,
					compact && styles.compact,
					className,
				)}
				style={textarea ? textAreaStyle : textFieldStyle}
			>
				{!hidden && (
					<FieldLabel
						htmlFor={inputId}
						title={title}
						isActive={isFocused}
						isEmpty={isEmpty}
						data-testid={`${testID}-label`}
						size="label"
						required={required}
					/>
				)}

				{tooltip && tooltip.length > 1 && (
					<div className={styles.tooltipWrapper}>
						<Tooltip text={tooltip} position="left" theme="darkGreen" />
					</div>
				)}

				{textarea && (
					<textarea
						{...sharedProps}
						className={styles.textarea}
						data-testid={testID}
						onBlur={handleOnBlur}
						onChange={handleOnChange}
						onFocus={handleOnFocus}
						style={textAreaStyle}
						title={title}
						value={value}
						ref={forrwardedRef}
					/>
				)}
				{!textarea && (
					<input
						{...sharedProps}
						type={type ?? 'text'}
						onBlur={handleOnBlur}
						onChange={handleOnChange}
						onFocus={handleOnFocus}
						onKeyDown={handleOnKeyDown}
						data-testid={testID}
						pattern={pattern}
						readOnly={readOnly}
						disabled={disabled}
						className={styles.input}
						title={title}
						value={value}
						ref={forrwardedRef}
					/>
				)}

				{type === 'search' && (
					<div className={styles.iconContainer}>
						<Icon
							name="magnifying-glass"
							size="1x"
							color="--primary-dark-green"
							className={styles.icon}
							testID="search-icon"
						/>
					</div>
				)}
			</div>
			{type !== 'search' && !hidden && (
				<div
					className={cls(styles.validationMessage, themeInfo.styles.textColor, compact ? styles.compact : undefined)}
					data-test-id="validation-error"
				>
					{!disabled ? validationMessage : ''}
				</div>
			)}
		</div>
	);
};
