import { BrandLine, ContainerFluid, Heading, Section } from '@/atoms';
import DynamicComponent from '@/bloks/DynamicComponent';
import {
	SectionTextStoryblok,
	TextGroupStoryblok,
	TextImageGroupStoryblok,
	TextTwoColumnsGroupStoryblok,
	ThemeV2Storyblok,
} from '@/components';
import { cls } from '@/utils';
import React from 'react';
import styles from './SectionText.module.scss';

interface BlokProps {
	blok: SectionTextStoryblok;
}

interface SectionTextProps {
	title?: string;
	theme: ThemeV2Storyblok[];
	groups: (TextImageGroupStoryblok | TextGroupStoryblok | TextTwoColumnsGroupStoryblok)[];
}

const blokProps = ({ blok }: BlokProps): SectionTextProps => ({
	title: blok.title,
	theme: blok.theme,
	groups: blok.group,
});

export const SectionText = ({ title, theme, groups }: SectionTextProps) => {
	const selectedTheme = theme?.[0]?.theme ?? 'dark-green';

	return (
		<ContainerFluid theme={selectedTheme} className={cls(selectedTheme === 'white' && styles.noBackgroundColor)}>
			<Section>
				{title && (
					<>
						<BrandLine className={styles.brandColor} />
						<Heading as="div" size="h2" title={title} marginBottom="3xl" />
					</>
				)}
				<div className={styles.group}>
					{groups?.map((group) => {
						return <DynamicComponent blok={group} key={group._uid} meta={{ theme: selectedTheme }} />;
					})}
				</div>
			</Section>
		</ContainerFluid>
	);
};

SectionText.blokProps = blokProps;
