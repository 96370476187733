import { Grid, Select } from '@/atoms';
import { useSearchIntegrations } from '@/contexts/Integration/searchIntegrationsContext';
import { t } from 'ttag';

interface SortProps {
	className?: string;
}

export const SortIntegrations: React.FC<SortProps> = () => {
	const { sortBy, setSortBy, setSortOrder, setSearchResult, setCurrentPage } = useSearchIntegrations();

	const sortOptions = [
		{
			value: 'relevance',
			label: t`Relevans`,
			id: 'relevance',
		},
		{
			value: 'custom_fields.appName',
			label: t`Bokstavsordning`,
			id: 'alphabetical',
			order: 'asc',
		},
		{
			value: 'custom_fields.weightedAverageScore',
			label: t`Betyg`,
			id: 'weightedAverageScore',
			order: 'desc',
		},
		{
			value: 'custom_fields.numberOfRatings',
			label: t`Flest betyg`,
			id: 'numberOfRatings',
			order: 'desc',
		},
	];

	const handleSortChange = (value: string) => {
		const order = sortOptions.find((option) => option.value === value)?.order || 'asc';
		setSortBy(value);
		setSortOrder(order);
		setSearchResult({ hits: [] });
		setCurrentPage(1);
	};

	const selectedOrderOption = sortOptions.find((order) => order.value === sortBy);

	return (
		<Grid columns={{ base: 1, lg: 12 }} colGap="xl" rowGap="xl">
			<Grid.Item colStart={{ base: 2, lg: 9 }} colSpan={{ base: 1, lg: 4 }}>
				<Select
					onSelect={(value) => handleSortChange(value)}
					testID="category"
					title={t`Välj sortering`}
					aria-label={t`Välj sortering`}
					data={sortOptions}
					selected={selectedOrderOption}
					onClear={() => handleSortChange('relevance')}
					id="integration-categories"
				/>
			</Grid.Item>
			<Grid.Item colStart={{ base: 2, lg: 1 }} colEnd={{ base: 1, lg: 12 }}></Grid.Item>
		</Grid>
	);
};
