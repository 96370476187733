import { BrandLine, Grid, Heading, ShowMoreButton, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CalendarItemStoryblok, CalendarStoryblok, LinkButtonStoryblok } from '@/components';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useState } from 'react';
import { t } from 'ttag';
import { LinkButton } from '../../Button';
import styles from './Calendar.module.scss';

interface CalendarItemProps {
	blok: CalendarItemStoryblok;
	className?: string;
}

const useMonth = (number: number) => {
	return (
		[t`Jan.`, t`Feb.`, t`Mar.`, t`Apr.`, t`Maj`, t`Jun.`, t`Jul.`, t`Aug.`, t`Sep.`, t`Okt.`, t`Nov.`, t`Dec.`][
			number
		] ?? ''
	);
};

const CalendarItem: React.FC<CalendarItemProps> = ({ blok }) => {
	const date = blok.date ? new Date(blok?.date?.replace(' ', 'T')) : new Date(); // placeholder is today
	const day = date.getDate();
	const year = date.getFullYear();
	const month = useMonth(date.getMonth());
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
	const alreadyOccured = date.getTime() < yesterday.getTime();

	if (alreadyOccured) {
		return null;
	}

	return (
		<li className={styles.calendarListItem}>
			{blok.showOnlyMonthAndYear ? (
				<div className={styles.date}>
					<span className={styles.smallDateText} data-testid="caldendar-year">
						{year}
					</span>
					<span className={styles.bigMonthText} data-testid="caldendar-month">
						{month}
					</span>
				</div>
			) : (
				<div className={styles.date}>
					<span className={styles.smallDateText} data-testid="caldendar-year">
						{year}
					</span>
					<span className={styles.bigDateText} data-testid="caldendar-day">
						{day}
					</span>
					<span className={styles.smallDateText} data-testid="caldendar-month">
						{month}
					</span>
				</div>
			)}
			<Grid
				columns={{ base: 1, sm: 2, md: '7fr 2fr', lg: '7fr 2fr', xl: '7fr 2fr' }}
				colGap="lg"
				rowGap="sm"
				className={styles.grid}
			>
				<Grid.Item>
					<Heading marginBottom="none" as="div" size="h6" title={blok?.title} data-testid="caldendar-header-h6" />
					{blok.description && <Text className={styles.description}>{blok.description}</Text>}
				</Grid.Item>
				<Grid.Item className={cls(styles.gridItem, styles.linkContainer)}>
					{blok.link?.map((link: LinkButtonStoryblok, index: number) => (
						<LinkButton key={index} {...LinkButton.blokProps({ blok: link })} variant="text" />
					))}
				</Grid.Item>
			</Grid>
		</li>
	);
};

interface BlokProps {
	blok: CalendarStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => {
	return { _editable: blok._editable, title: blok.title, dates: blok.dates };
};

interface Props {
	_editable?: string;
	title?: string;
	dates?: CalendarItemStoryblok[];
}

/*
 * Calendar - mainly used to present a list of important date on IR pages
 */
export const Calendar: Blok<Props, BlokProps> = ({ _editable, dates = [], title }) => {
	const today = new Date();
	const [showMore, setShowMore] = useState(false);

	const futureDates = dates.filter(({ date }) => new Date(date.replace(/-/g, '/')) > today);

	const relevantDates =
		futureDates
			.slice(0, showMore ? 8 : 3)
			.sort((a, b) => {
				return new Date(b.date).getTime() - new Date(a.date).getTime();
			})
			.reverse() ?? [];

	return (
		<div {...editableBlok({ _editable })} className={styles.container}>
			<BrandLine className={styles.brandLine} />
			{title && <Heading as="h2" size="h4" title={title} marginBottom="xl" data-testid="caldendar-header-h4" />}
			<ul className={styles.calendarList}>
				{relevantDates?.map((date, index: number) => <CalendarItem key={index} blok={date} />)}
			</ul>
			{!showMore && futureDates?.length > 3 && (
				<ShowMoreButton onClick={() => setShowMore(!showMore)} icon="chevron-down" className={styles.button}>
					{t`Visa fler`}
				</ShowMoreButton>
			)}
		</div>
	);
};

Calendar.blokProps = blokProps;
