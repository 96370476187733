import { TableBlok, TableHead } from '@/bloks/RichText/TableBlok';
import { CustomTableStoryblok, ThemeV2Storyblok } from '@/components';

interface Props {
	blok: CustomTableStoryblok;
	theme?: ThemeV2Storyblok['theme'];
	meta: Record<string, any>;
}

export const CustomTable: React.FC<Props> = ({ blok, theme }) => {
	const { leftTdIsLabel } = blok;

	return blok.table ? (
		<TableBlok
			table={{
				value: { thead: blok.table.thead as unknown as TableHead[], tbody: blok?.table?.tbody, fieldtype: 'table' },
			}}
			theme={theme}
			leftTdIsLabel={leftTdIsLabel}
			showBorderRight={false}
		/>
	) : null;
};
