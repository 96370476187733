import { BrandLine, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderTwoImagesStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { getThemeInfo } from '@/themes';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import { LinkButton, LinkButtonProps } from '../../Button';
import styles from './HeaderTwoImages.module.scss';

interface BlokProps {
	blok: HeaderTwoImagesStoryblok;
}

interface Props {
	imageLeft: ImageAssetProps;
	imageRight: ImageAssetProps;
	title: string;
	text: string;
	linkButton: LinkButtonProps[];
	_editable?: string;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	imageLeft: blok.imageLeft,
	imageRight: blok.imageRight,
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton.map((btn) => LinkButton.blokProps({ blok: btn })),
	_editable: blok._editable,
});

export const HeaderTwoImages: Blok<Props, BlokProps> = ({
	imageLeft,
	imageRight,
	title,
	text,
	linkButton,
	_editable,
}) => {
	const theme = 'green';
	const themeInfo = getThemeInfo(theme);
	const { menuVersionV3 } = useGlobalMenu();

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const contentName = `1|HeaderTwoImages`;
	const contentPiece = title;
	let contentTarget = linkButton.length ? 'multiple interactions possible' : 'no interactions possible';
	if (linkButton?.length === 1) contentTarget = 'one interaction possible';

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction(
			'contentInteraction',
			`${slug}|${contentName}`,
			contentPiece,
			contentTarget,
		);
	}

	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece, contentTarget }}>
			<ContainerFluid className={styles.desktop} {...editableBlok({ _editable })} theme={theme}>
				<div className={styles.headerContainer}>
					<div className={styles.imageLeft}>
						<ImageAsset
							absolute
							priority
							loading="eager"
							objectPosition={'center center'}
							objectFit="cover"
							fill
							asset={imageLeft}
						/>
					</div>
					<div className={styles.imageRight}>
						<ImageAsset
							absolute
							priority
							loading="eager"
							objectPosition={'center center'}
							objectFit="cover"
							fill
							asset={imageRight}
						/>
					</div>

					<Section
						as="header"
						className={cls(styles.content, menuVersionV3 && styles.menuVersionV3, themeInfo.styles.bgColor)}
						maxWidth="custom"
						paddingX={{ base: 'xl', lg: 'none' }}
						paddingY={{ base: 'none' }}
					>
						<div className={styles.inner}>
							<BrandLine marginBottom="xl" />
							<Heading className={styles.title} as="h1" marginBottom="xl" title={title} />

							<Text className={styles.text} marginBottom="3xl">
								{text}
							</Text>

							{linkButton.map((btn, index) => (
								<div className={styles.btnPosition} key={index}>
									<LinkButton
										{...btn}
										variant="filled"
										onClick={() => {
											trackContentInteraction(btn.children as string);
										}}
									/>
								</div>
							))}
						</div>
					</Section>
				</div>
			</ContainerFluid>
			<ContainerFluid className={styles.mobile} {...editableBlok({ _editable })} theme={theme}>
				<div className={styles.headerContainer}>
					<div className={styles.imageRight}>
						<ImageAsset
							absolute
							priority
							loading="eager"
							objectPosition={'center center'}
							objectFit="cover"
							fill
							asset={imageRight}
						/>
						<div className={styles.overlay} />
					</div>

					<Section
						as="header"
						className={styles.content}
						paddingX={{ base: 'xl', lg: '3xl' }}
						paddingY={{ base: 'none' }}
					>
						<BrandLine className={styles.brandLine} />
						<Heading className={styles.title} as="div" size="h1" marginBottom="xl" title={title} noId />
					</Section>
				</div>
				<div className={cls(styles.textWrapper, themeInfo.styles.bgColor)}>
					<Text className={styles.text} marginBottom="xl">
						{text}
					</Text>

					{linkButton.map((btn, index) => (
						<div className={styles.btnPosition} key={index}>
							<LinkButton
								{...btn}
								variant="filled"
								onClick={() => {
									trackContentInteraction(btn.children as string);
								}}
							/>
						</div>
					))}
				</div>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};
HeaderTwoImages.blokProps = blokProps;
