import { Button, ContainerFluid, Grid, Heading, Icon, TextField, Tooltip } from '@/atoms';
import { CalculatorDescription } from '@/bloks/Calculator/CalculatorDescription';
import { ProfitMarginCalculatorStoryblok, TooltipStoryblok } from '@/components';
import { handleCalculatorSubmitEvent } from '@/utils/piwik';
import { Chart, registerables } from 'chart.js';
import React, { FormEvent, useEffect, useState } from 'react';
import { t } from 'ttag';
import styles from '../CalculatorStyling.module.scss';

interface ProfitMarginInfo {
	percent?: number;
	cost?: number;
	profit?: number;
}

interface Props {
	blok: ProfitMarginCalculatorStoryblok;
}

interface ProfitMarginState {
	salesPrice: number;
	cost: number;
}

export interface ChartData {
	label: string;
	value: number;
	color: string;
}

export const ProfitMarginCalculator: React.FC<Props> = ({ blok }) => {
	const [profitMarginState, setProfitMarginState] = useState<ProfitMarginState>({
		salesPrice: 0,
		cost: 0,
	});
	const [profitMarginInfo, setProfitMarginInfo] = useState<ProfitMarginInfo>({
		percent: undefined,
		cost: undefined,
		profit: undefined,
	});

	if (registerables) {
		Chart.register(...registerables);
	}

	const resetProfitMarginInfo = ({ salesPrice, cost }: ProfitMarginState) => {
		if (salesPrice == 0 && cost == 0) {
			setProfitMarginInfo({});
		}
	};

	useEffect(() => {
		const el = document.getElementById('profitMarginChart') as HTMLCanvasElement;

		if (!el) {
			return;
		}
		const chartData: Array<ChartData> = [
			{
				label: t` Vinst`,
				value: profitMarginInfo.profit || 0,
				color: '#FE85B5',
			},
			{
				label: t` Kostnader`,
				value: profitMarginInfo.cost || 0,
				color: '#DDE1E0',
			},
		];

		const emptyChartData = [
			{
				value: 100,
				color: '#DDE1E0',
			},
		];

		const emptyChartDataDataset = [
			{
				data: emptyChartData.map((data) => data.value),
				backgroundColor: emptyChartData.map((data) => data.color),
				hoverOffset: 0,
			},
		];

		chartData.sort((a, b) => b.value - a.value);

		const enableChart = profitMarginInfo.percent && profitMarginInfo.percent >= 0;

		const chart = new Chart(el, {
			type: 'pie',
			data: {
				labels: chartData.map((data) => data.label),
				datasets: enableChart
					? [
							{
								data: chartData.map((data) => data.value),
								backgroundColor: chartData.map((data) => data.color),
								hoverOffset: 25,
							},
						]
					: emptyChartDataDataset,
			},
			options: {
				elements: {
					arc: {
						borderWidth: 0,
					},
				},
				layout: {
					padding: {
						bottom: 25,
						top: 25,
						left: 25,
						right: 25,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
				},
				events: enableChart ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'] : [],
			},
		});

		return () => {
			chart.destroy();
		};
	}, [profitMarginInfo]);

	function handleSubmit(event: FormEvent) {
		event.preventDefault();

		handleCalculatorSubmitEvent('ProfitMarginCalculator');

		const cost = profitMarginState.cost;
		const salesPrice = profitMarginState.salesPrice;

		setProfitMarginInfo({
			percent: ((salesPrice - cost) / salesPrice) * 100,
			cost,
			profit: salesPrice - cost,
		});
	}

	const CalculatorResult = () => (
		<div className={styles.description}>
			<Heading as="div" title={t`Vinstmarginal`} size="h5" marginBottom="sm" />
			<div className={styles.chart}>
				<div>
					<canvas id="profitMarginChart" />
					<Heading
						className={styles.coverageContribution}
						as="div"
						title={`${profitMarginInfo.percent?.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`}
						size="h2"
						marginBottom="sm"
					/>
				</div>
			</div>
		</div>
	);

	const getCalculatorResult = () => {
		if (profitMarginInfo.percent != undefined) {
			return <CalculatorResult />;
		}
		return <CalculatorDescription blok={blok} />;
	};

	const salesTooltip = blok.salesPriceTooltip[0] as unknown as TooltipStoryblok;
	const costTooltip = blok.costTooltip[0] as unknown as TooltipStoryblok;

	return (
		<ContainerFluid marginBottom="3xl">
			<Grid columns={{ base: 1, lg: 2 }}>
				<Grid.Item className={styles.calculator}>
					<div className={styles.headingRow}>
						<Icon name="calculator" />
						<Heading className={styles.heading} as="div" title={t`Vinstmarginal`} size="h5" marginBottom="none" />
					</div>
					<form onSubmit={handleSubmit}>
						<div className={styles.textField}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Försäljningspris - kr`} as="div" size="h6" />
								<Tooltip theme="darkGreen" className={styles.toolTip} text={salesTooltip?.text ?? ''} />
							</div>
							<TextField
								id="profit-margin_sales-price-field"
								title={t`Försäljningspris`}
								testID="profit-margin_selling-price-field"
								type="number"
								value={profitMarginState.salesPrice == 0 ? '' : profitMarginState.salesPrice}
								onChange={(event) => {
									const state = { ...profitMarginState, salesPrice: event.target.value };
									setProfitMarginState(state);
									resetProfitMarginInfo(state);
								}}
								required
								className={styles.numberInputFields}
							/>
						</div>
						<div className={styles.textField}>
							<div className={styles.textFieldHeading}>
								<Heading title={t`Kostnader - kr`} as="div" marginBottom="md" size="h6" />
								<Tooltip theme="darkGreen" className={styles.toolTip} text={costTooltip?.text ?? ''} />
							</div>
							<TextField
								id="profit-margin_costs-field"
								title={t`Kostnader`}
								testID="profit-margin_costs-field"
								type="number"
								value={profitMarginState.cost == 0 ? '' : profitMarginState.cost}
								onChange={(event) => {
									const state = { ...profitMarginState, cost: event.target.value };
									setProfitMarginState(state);
									resetProfitMarginInfo(state);
								}}
								required
								className={styles.numberInputFields}
							/>
						</div>
						<Button
							type="submit"
							size="large"
							testID="profit-margin_compute-button"
							className={styles.calculateButton}
							disabled={profitMarginState.cost == 0 || profitMarginState.salesPrice == 0}
						>{t`Beräkna`}</Button>
						{profitMarginState.cost != 0 && profitMarginState.salesPrice != 0 && (
							<div className={styles.clearFieldsBtnContainer}>
								<button
									type="button"
									data-test-id="profit-margin_clear-fields"
									className={styles.clearFieldsButton}
									onClick={() => {
										setProfitMarginState({
											cost: 0,
											salesPrice: 0,
										});
										setProfitMarginInfo({
											percent: undefined,
											cost: undefined,
											profit: undefined,
										});
									}}
								>{t`Rensa`}</button>
							</div>
						)}
					</form>
				</Grid.Item>
				<Grid.Item>{getCalculatorResult()}</Grid.Item>
			</Grid>
		</ContainerFluid>
	);
};
